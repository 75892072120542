import { Button, Rating, TextInput } from "flowbite-react"
import { useState } from "react"
import { HiXCircle, HiOutlineTrash, HiOutlineCloudDownload } from 'react-icons/hi'
import Swal from "sweetalert2"
import axios from "./../../interceptor/AxiosInterceptor"



const ItemIndicador = ({ambito, indicador,eliminarIndi})=>{

    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    const [mostrarFormArchivo,setMostrarFormArchivo]  = useState(false)
    const [mostrarListaArchivos,setMostrarListaArchivos] = useState(false)
    const [archivosIndicador,setArchivosIndicador] = useState([])


    function adjuntarArchivo(indi,event){

        let formData = new FormData()
        formData.append("idIndi",indi.id)
        formData.append("archivo",event.target.files[0])

        axios.post(apiURl+'/categorizaciones/agregarArchivoIndicadorDocente',formData)
        .then(res=>{
            Swal.fire({text:'Archivo agregado correctamente', icon:'success'})
            setMostrarFormArchivo(false)
        }).catch(err=>{
            Swal.fire({text:err.response.data, icon:'error'})
        })
    }

    function cargarArchivosAdjuntos(muestro,indi){
        if(muestro === true){
            axios.get(apiURl+'/categorizaciones/obtenerArchivosPorIndicador/'+indi.id)
            .then(res=>{
                setArchivosIndicador(res.data.archivosIndicador)
            }).catch(err=>{
                Swal.fire({text:err.response.data, icon:'error'})
            })
        }
       

        setMostrarListaArchivos(muestro)
        
    }


    function descargarArchivo(archivo){
       
            axios
            .get(apiURl + "/categorizaciones/descargarArchivoIndicador/" + archivo.id, {
              responseType: "blob",
            })
            .then((res) => {
              const url = URL.createObjectURL(res.data);
              const a = document.createElement("a");
              a.href = url;
              a.download = archivo.nombre;
              a.style.display = "none";
              document.body.appendChild(a);
              a.click();
              a.remove();
              URL.revokeObjectURL(url);
            })
            .catch((error) => {
              Swal.fire({ text: error, icon: error });
            });
        
    }

    return <>
        <div>
                                <dl>
                                    <dt className="text-sm text-left font-medium text-gray-500 dark:text-gray-400">{indicador.nombre}</dt>
                                    <dd className="flex items-center">
                                        <div className="w-full rounded">
                                            <Rating.Advanced percentFilled={indicador.porcentaje}  className="mb-2">
                                                <Button className="float-left mr-3" onClick={()=>eliminarIndi(indicador)} size="xs" color="failure"><HiOutlineTrash /></Button>
                                                <Button className={mostrarFormArchivo ? 'hidden' :'block float-left mr-3'} onClick={()=>setMostrarFormArchivo(true)} size="xs">Adjuntar archivos</Button>
                                                <Button className={mostrarFormArchivo ? 'block float-left mr-3' :'hidden'} size="xs" color="gray" onClick={()=>setMostrarFormArchivo(false)} type="submit"><HiXCircle  className="mr-2 h-5 w-5"/>Cancelar</Button>
                                                <Button className={mostrarListaArchivos ? 'hidden' :'block float-left mr-3'} size="xs" color="gray" onClick={()=>cargarArchivosAdjuntos(true, indicador)} type="submit"><HiXCircle  className="mr-2 h-5 w-5"/>Ver adjuntos</Button>
                                                <Button className={mostrarListaArchivos ? 'block float-left mr-3' :'hidden'} size="xs" color="gray" onClick={()=>cargarArchivosAdjuntos(false,indicador)} type="submit"><HiXCircle  className="mr-2 h-5 w-5"/>Ocultar adjuntos</Button>
                                                </Rating.Advanced>
                                        </div>
                                    </dd>
                                </dl>
                                <div className={mostrarFormArchivo ? 'block' :'hidden'}>
                                    <div className="flex items-center justify-between mt-5">
                                        <label htmlFor="archivos" className="block text-sm font-medium leading-6 text-gray-900">Archivos</label>
                                    </div>
                                    <TextInput onChange={(e)=>adjuntarArchivo(indicador,e)} id="archivos" className="w-96" name="archivos" aria-label="archivos" 
                                            type="file" />
                                </div>
                                <div className={mostrarListaArchivos ? 'block pb-10' :'hidden'}>
                                    <br/>
                                    {archivosIndicador.length > 0 ? archivosIndicador.map(a=>{
                                        return  <Button onClick={()=>descargarArchivo(a)} className="float-left mr-3" size="xs" outline gradientDuoTone="cyanToBlue">
                                                    <HiOutlineCloudDownload  className="h-5 w-5 text-cyan-600 dark:text-cyan-500" /> {a.nombre}
                                                </Button>
                                    }): ''}
                                    <br/>
                                </div>
                            </div>
    </>
}

export default ItemIndicador
import axios from "./../../interceptor/AxiosInterceptor"
import { Table } from "flowbite-react"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import ItemRowConfiguracion from "./ItemRowConfiguracion";

const TablaConfiguraciones = () => {
    const [configuraciones, setConfiguraciones] = useState([])
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT

    useEffect(() => {

        axios.get(apiURl + '/configuracion/obtenerTodasLasConfiguraciones')
            .then(res => {
                setConfiguraciones(res.data.configuracion)
                
            })
            .catch(err => {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al obtener las configuraciones',
                })
            })
    }, [])


    return <>
        <Table hoverable className="mt-10">
            <Table.Head>
                <Table.HeadCell>
                    Codigo Configuracion
                </Table.HeadCell>
                <Table.HeadCell>
                    Valor
                </Table.HeadCell>
                <Table.HeadCell>
                    Acciones
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {configuraciones.map(c => <ItemRowConfiguracion key={c.id} configuracion={c} />)}
            </Table.Body>
        </Table>

    </>
}
export default TablaConfiguraciones;

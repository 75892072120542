import axios from "axios";

const instance = axios.create();

instance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      Authorization: sessionStorage.getItem("token"),
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      sessionStorage.removeItem("token");
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default instance;

import { TextInput, Button } from "flowbite-react";
import { useState } from "react";
import { HiArrowSmLeft, HiOutlineCheckCircle } from "react-icons/hi";
import Swal from "sweetalert2";
import axios from "./../../interceptor/AxiosInterceptor";
import { Link } from "react-router-dom";

const CrearInstitucion = () => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const [institucion, setInstitucion] = useState({});

  function capturarFormulario(event) {
    setInstitucion({
      ...institucion,
      [event.target.name]: event.target.value,
    });
  }

  function submitForm(event) {
    event.preventDefault();
  }

  function enviarForm() {
    axios
      .post(apiURl + "/institucionApi/guardarInstitucion", institucion)
      .then((res) => {
        Swal.fire({ text: "Institución creada", icon: "success" });
        setInstitucion({
          nombre: "",
        });
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }

  return (
    <>
      <h4 className="text-2xl mt-5 text-center">Crear Institución</h4>
      <div className="mt-10 flex flex-col items-center justify-center">
        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="nombre"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre Instutución
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="nombre"
            className="w-96"
            name="nombre"
            aria-label="Nombre institucion"
            type="text"
            value={institucion.nombre}
          />

          <div className="columns-2 mt-10">
            <div>
              <Button onClick={enviarForm} type="submit">
                <HiOutlineCheckCircle className="mr-2 h-5 w-5" />
                Guardar
              </Button>
            </div>
            <div>
              <Link to="/admin">
                <Button type="submit">
                  <HiArrowSmLeft className="mr-2 h-5 w-5" />
                  Volver
                </Button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CrearInstitucion;

import axios from "./../../interceptor/AxiosInterceptor"
import { Table} from "flowbite-react"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import ItemRowUsuario from "./ItemRowUsuario"

const TablaUsuarios = ()=> {

    const [usuarios,setUsuarios] = useState([])

    useEffect(()=>{
        const apiURl  = process.env.REACT_APP_BACKEND_API_ENDPOINT
        axios.get(apiURl+'/usuarios/obtenerTodosLosUsuarios')
        .then(res=>{
            setUsuarios(res.data.usuarios)
        })
        .catch(err=>{
            Swal.fire({
                title: 'Error',
                text: 'Error al buscar usuarios',
            })
        })
    },[usuarios])


    return <>

      <Table hoverable className="mt-10">
      <Table.Head>
        <Table.HeadCell>
           Nombre
        </Table.HeadCell>
        <Table.HeadCell>
          Email
        </Table.HeadCell>
        <Table.HeadCell>
          Roles
        </Table.HeadCell>
        <Table.HeadCell>
          Acciones
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">
        {usuarios.map(u =><ItemRowUsuario key={u.id}  usuario={u} />)}
      </Table.Body>
    </Table>

    </>

}

export default TablaUsuarios
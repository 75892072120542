import { Card } from "flowbite-react";

import NotasRamo from "./NotasRamo";

const Notas = ({ matriculas }) => {
  return (
    <>
      {matriculas.length > 0 ? (
        matriculas.map((m) => (
          <>
            <Card className="mt-3" key={m.id}>
              <h3 class="text-1xl font-bold dark:text-white pt-3">
                {m.descCarrera}
              </h3>
              <NotasRamo matricula={m} />
            </Card>
          </>
        ))
      ) : (
        <span className="italic text-sm 666">
          No existen matrículas asociadas al estudiante
        </span>
      )}
    </>
  );
};

export default Notas;

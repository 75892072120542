import {ToggleSwitch} from 'flowbite-react';
import { useEffect, useState } from 'react';

const ItemSwitchRol = ({setUsuario,rol,usuario} )=>{
  
    const [checked,setChecked] = useState(false)

    function handleSwitchInner(){

        let hasRol = usuario.roles.findIndex(r => r.id === rol.id)
        
        if(hasRol !== -1){  
            usuario.roles.splice(hasRol,1)
            setChecked(false)
        }else {
            usuario.roles.push(rol)
            setChecked(true)
        }

        setUsuario(usuario)
    }

    useEffect(()=>{
        if(usuario.roles){
            let rolFound = usuario.roles.find(r => r.name === rol.name)
             rolFound ? setChecked(true) : setChecked(false);
        }

    },[setUsuario,rol,usuario])
    
    return <>
            <ToggleSwitch className="mt-2" checked={checked} label={rol.name} onChange={handleSwitchInner} />
    </>
}

export default ItemSwitchRol
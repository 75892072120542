import { useParams,Link } from "react-router-dom"
import { Textarea, TextInput, Button, Datepicker } from "flowbite-react"
import {HiOutlineCheckCircle,HiArrowSmLeft} from "react-icons/hi"
import { useState } from "react"
import axios from './../../interceptor/AxiosInterceptor'
import Swal from "sweetalert2"
import moment from "moment"

const AddCapacitacion = ()=>{

    const {idDocente} = useParams()
    const [capacitacion,setCapacitacion] = useState({})
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    
    function caputrarForm(event){
        if(event.target.files){
            setCapacitacion({
                ...capacitacion,
                'archivo':  event.target.files[0]
            })
        }else{
            setCapacitacion({
                ...capacitacion,
                [event.target.name]: event.target.value
            })
         }
    }

    function enviarForm(){
        
        const formData = new FormData();
        for (const key of Object.keys(capacitacion)) {
            formData.append(key, capacitacion[key]);
        }
        formData.append('idDocente',idDocente)

       if(!capacitacion.nombre || !capacitacion.fecInicio 
            || !capacitacion.fecFin || !capacitacion.numHoras
            || !capacitacion.detalle  || !capacitacion.calificacion){
                Swal.fire({text:'Debe completar todos los datos del formulario para continuar', icon:'error'})
                return;
            }

        axios.post(apiURl+'/docentes/addCapacitacion',formData)
        .then(res=>{
           Swal.fire({text:'Se ha agregado la capacitación correctamente', icon:'success'})
           setCapacitacion({
            nombre:'',
            fecInicio:'',
            fecFin:'',
            numHoras:'',
            detalle:'',
            calificacion:'',
            archivo:''
           })
        }).catch(err=>{
            Swal.fire({text:err.response.data, icon:'error'})
        })

    }

    function capturarFecInicio(value){
        setCapacitacion({
            ...capacitacion,
            'fecInicio': value
        })
    }

    function capturarFecFin(value){
        setCapacitacion({
            ...capacitacion,
            'fecFin': value
        })
    }


    return <>
        <h4 className="text-2xl mt-5 text-center">Agregar capacitación</h4>
         <div className="mt-10 flex flex-col items-center justify-center"> 
            
            <div className="flex items-center justify-between mt-5">
                <label htmlFor="nombre" className="block text-sm font-medium leading-6 text-gray-900">Nombre del curso</label>
            </div>
            <TextInput onChange={caputrarForm} name="nombre" value={capacitacion.nombre}
            className="w-96 " type="text" min="1" max="7" id="nombre"   />

            <div className="flex items-center justify-between mt-5">
                <label htmlFor="fecInicio" className="block text-sm font-medium leading-6 text-gray-900">Fecha de inicio</label>
            </div>
            <Datepicker onSelectedDateChanged={capturarFecInicio} className="w-96" 
                value={capacitacion.fecInicio ? moment(capacitacion.fecInicio).format('DD-MM-yyyy') : ''}
                name="fecInicio" labelClearButton="Limpar" 
                labelTodayButton="Hoy" language="cl-CL"/>

            <div className="flex items-center justify-between mt-5">
                <label htmlFor="fecFin" className="block text-sm font-medium leading-6 text-gray-900">Fecha de fin</label>
            </div>
            <Datepicker onSelectedDateChanged={capturarFecFin} className="w-96"
                value={capacitacion.fecFin ? moment(capacitacion.fecFin).format('DD-MM-yyyy') : ''}
                name="fecFin" labelClearButton="Limpar" labelTodayButton="Hoy" 
                language="cl-CL"/>

            <div className="flex items-center justify-between mt-5">
                <label htmlFor="numHoras" className="block text-sm font-medium leading-6 text-gray-900">Número de horas</label>
            </div>
            <TextInput  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                value={capacitacion.numHoras}
                onChange={caputrarForm} name="numHoras" className="w-96 " type="number" min="1" max="7" id="numHoras"   />


            <div className="flex items-center justify-between mt-5">
                <label htmlFor="detalle" className="block text-sm font-medium leading-6 text-gray-900">Detalle comentario/observación</label>
            </div>
            <Textarea onChange={caputrarForm} name="detalle"  id="detalle" 
                value={capacitacion.detalle}
                className="w-96"  rows={8} />

            <div className="flex items-center justify-between mt-5">
                <label htmlFor="calificacion" className="block text-sm font-medium leading-6 text-gray-900">Porcentaje de logro</label>
            </div>
            <input onChange={caputrarForm} name="calificacion" 
                value={capacitacion.calificacion ? capacitacion.calificacion : 0}
                className="w-96" type="range" id="test5" min="0" max="100" />
            <TextInput disabled="true" onChange={caputrarForm} name="calificacion" 
                className="w-96 " type="text" min="1" max="7" id="calificacion"
                value={capacitacion.calificacion ? capacitacion.calificacion+'%' : '0%'}  />

            <div className="flex items-center justify-between mt-5">
                <label htmlFor="archivo" className="block text-sm font-medium leading-6 text-gray-900">Adjuntar certificado PDF</label>
            </div>
            <input onChange={caputrarForm} name="archivo" 
                className="w-96 block text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
                value={capacitacion?.archivo?.fileName ? capacitacion?.archivo?.fileName : ''}
                id="archivo" type="file"></input>

            <div className="columns-2 mt-10">
                 <div>
                    <Button onClick={enviarForm} type="submit"><HiOutlineCheckCircle className="mr-2 h-5 w-5 float-left"/>Guardar capacitación</Button>
                 </div>
                 <div>
                    <Link to={'/fichaDocente/'+idDocente}>
                        <Button type="submit">
                        <HiArrowSmLeft className="mr-2 h-5 w-5" />Volver a la ficha del docente</Button>
                    </Link>
                </div>
            </div>
        
        </div>
    </>

}

export default AddCapacitacion
import axios from "./../../interceptor/AxiosInterceptor";
import { Button, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { HiBan, HiPencilAlt } from "react-icons/hi";

const TablaViasIngreso = ()=>{

    const [viasIngreso, setViasIngreso] = useState([]);
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  
    useEffect(() => {
      axios
        .get(apiURl + "/viaIngresoApi/obtenerTodasViasIngreso")
        .then((res) => {
          setViasIngreso(res.data.viasIngreso);
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text: "Error al buscar vías de ingreso",
          });
        });
    }, []);
  
    function eliminarViaIngreso(viaSeleccionada) {

      Swal.fire({
        title: 'Está seguro de eliminar la vía de ingreso ?',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(apiURl+'/viaIngresoApi/eliminarViaIngreso',viaSeleccionada)
          .then(res=>{
            setViasIngreso(res.data.viasIngreso);
            Swal.fire({text:'Se ha eliminado la vía de ingreso correctamente',icon:'success'})
          }).catch(err=>{
            Swal.fire({text:'Error a eliminar',icon:'error'})
          })
  
        }
      })
    }
  
    return (
      <>
        <Table hoverable className="mt-10">
          <Table.Head>
            <Table.HeadCell>Nombre</Table.HeadCell>
            <Table.HeadCell>Acciones</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {viasIngreso.map((v) => (
              <Table.Row  key={v.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {v.nombre}
                </Table.Cell>
  
                <Table.Cell>
                  <div className="columns-2">
                    <div className="w-0">
                      <Link
                        className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
                        to={"/editarViaIngreso/" + v.id}
                      >
                        <Button>
                          <HiPencilAlt className="" />
                        </Button>
                      </Link>
                    </div>
                    <div className="w-0">
                      <Button color="failure" onClick={()=>eliminarViaIngreso(v)} aria-label="Deshabilitar" >
                        <HiBan className="" />
                      </Button>
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  };
  

export default TablaViasIngreso
import { Footer } from 'flowbite-react';

const FooterApp = ()=>{

  return <>
    <Footer container>
      <Footer.Copyright by="Todos los derechos reservados 2023 Santiago CFT" />
    </Footer>
  </>
}

export default FooterApp

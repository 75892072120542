import { useEffect, useState, useContext } from "react"
import { useParams, Link } from "react-router-dom"
import axios from './../interceptor/AxiosInterceptor'
import Swal from "sweetalert2"
import { Textarea,Button } from "flowbite-react"
import { AuthContext } from "../../context/AuthContext"
import { HiArrowSmLeft } from 'react-icons/hi'

const CrearObservacionDocente = ()=>{

    const {idEstudiante} = useParams()
    const [estudiante,setEstudiante] = useState({})
    const [observacion,setObservacion] = useState()
    const {usuarioLogeado} = useContext(AuthContext)
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT


    function capturarObs(event){
        setObservacion(event.target.value)
    }

    function agregarObs(){
        axios.post(apiURl+'/estudiantes/agregarObservacionFicha',
        {   userEmail:usuarioLogeado.email,
            idEstudiante:idEstudiante,
            observacion:observacion
        })
        .then(res=>{
            Swal.fire({text:'Se ha añadido el comentario a la ficha del estudiante',icon:'success'})
            setObservacion('')
        }).catch(err=>{
            Swal.fire({text:err.response.data, icon:'error'})
        })
    }

    useEffect(()=>{
        
      axios.get(apiURl+'/estudiantes/obtenerEstudiantePorId/'+idEstudiante)
      .then(res=>{
        setEstudiante(res.data.estudiante)
      }).catch(err=>{
          Swal.fire({text:'Error al obtener estudiante', icon:'error'})
      })
    },[])

    return <>
        <div class="md:container md:mx-auto ml-5 mt-5">
        
            <h3 class="text-2xl text-gray-700 font-bold mb-6 ml-3"> Agregar comentario a ficha del estudiante {estudiante.nombre} {estudiante.primerApellido}  {estudiante.segundoApellido}</h3>
            <div className="flex items-center justify-between mt-5">
                <label htmlFor="detalle" className="block text-sm font-medium leading-6 text-gray-900">Detalle comentario/observación</label>
            </div>
            <Textarea value={observacion} onChange={capturarObs}  id="detalle"  rows={8} />
            <div className="mt-10 float-left mr-3">
                <Button onClick={agregarObs}  type="submit">Añadir</Button>
            </div>
            <div className="mt-10">
                <Link to={'/fichaEstudiante/'+idEstudiante}>
                    <Button  type="submit"><HiArrowSmLeft className="mr-2 h-5 w-5" />Volver</Button>
                </Link>
            </div>
        </div>
    </>

}

export default CrearObservacionDocente
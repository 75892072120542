import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "./../../interceptor/AxiosInterceptor";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import Swal from "sweetalert2";
import ItemSwitchRol from "./ItemSwitchRol";
import { Link } from "react-router-dom";
import { HiArrowSmLeft, HiOutlineCheckCircle } from "react-icons/hi";

const EditarUsuario = () => {
  const [usuario, setUsuario] = useState({});
  const [roles, setRoles] = useState([]);
  const { idUsuario } = useParams();
  const [cambiarPassDisabled, setCambiarPassDisabled] = useState(false);
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  function submitForm(event) {
    event.preventDefault();
  }

  function capturarFormulario(event) {
    setUsuario({
      ...usuario,
      [event.target.name]: event.target.value,
    });
  }

  function enviarForm() {
    axios
      .post(apiURl + "/usuarios/guardarUsuario", usuario)
      .then((res) => {
        Swal.fire({ text: "Usuario guardado correctamente", icon: "success" });
      })
      .catch((err) => {
        Swal.fire({ text: "Erro al guardar usuario", icon: "error" });
      });
  }

  function handlePass(event) {
    setCambiarPassDisabled(event.target.checked);
  }

  useEffect(() => {
    axios
      .get(apiURl + "/usuarios/obtenerUsuario/" + idUsuario)
      .then((res) => {
        setUsuario(res.data.usuario);
      })
      .catch((err) => {
        Swal.fire({ icon: "error", text: "Error al buscar datos del usuario" });
      });

    axios
      .get(apiURl + "/rolesApi/obtenerRoles")
      .then((res) => {
        setRoles(res.data.roles);
      })
      .catch((err) => {
        Swal({ text: "Error al buscar roles", icon: "error" });
      });
  }, []);

  return (
    <>
      <div className="mt-10 flex flex-col items-center justify-center">
        <p className="text-green-700 text-xl mb-3">
          Editar usuario {usuario.username}
        </p>

        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre Completo
            </label>
          </div>
          <TextInput
            id="username"
            onChange={capturarFormulario}
            name="username"
            aria-label="Nombre completo"
            type="text"
            placeholder="Nombre completo"
            value={usuario.username}
            className="w-96"
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico
            </label>
          </div>
          <TextInput
            id="email"
            onChange={capturarFormulario}
            name="email"
            aria-label="Correo electrónico"
            type="text"
            placeholder="Correo electrónico"
            value={usuario.email}
            className="w-96"
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Contraseña
            </label>
          </div>
          <TextInput
            disabled={!cambiarPassDisabled}
            id="password"
            onChange={capturarFormulario}
            name="password"
            aria-label="Enter your password"
            type="password"
            placeholder="Password"
            value={usuario.password}
            className="w-96"
          />
          <Checkbox
            id="cambiarPass"
            value={cambiarPassDisabled}
            onChange={handlePass}
          />
          <Label htmlFor="cambiarPass" className="ml-2">
            Cambiar contraseña
          </Label>

          <div className="flex grid grid-cols-2 gap-2 mt-5" id="toggle">
            {roles.map((rol) => (
              <ItemSwitchRol
                key={rol.id}
                setUsuario={setUsuario}
                rol={rol}
                usuario={usuario}
              />
            ))}
          </div>

          <div className="columns-2 mt-10">
            <div>
              <Button className="" onClick={enviarForm} type="submit">
                <HiOutlineCheckCircle className="mr-2 h-5 w-5" />
                Guardar
              </Button>
            </div>

            <div>
              <Link to="/admin">
                <Button type="submit">
                  <HiArrowSmLeft className="mr-2 h-5 w-5" />
                  Volver
                </Button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditarUsuario;

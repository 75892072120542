import {
  Badge,
  Button,
  Modal,
  Dropdown,
  Card,
  Breadcrumb,
} from "flowbite-react";
import moment from "moment";
import axios from "./../interceptor/AxiosInterceptor";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  HiChevronDoubleRight,
  HiChevronDoubleLeft,
  HiCheckCircle,
  HiOutlineUserRemove,
} from "react-icons/hi";
import {
  Bs1CircleFill,
  Bs2CircleFill,
  Bs3CircleFill,
  Bs4CircleFill,
  Bs5CircleFill,
} from "react-icons/bs";

const AcordionAlertas = ({ alerta, metObtienAlertas }) => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const { usuarioLogeado } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTipo, setOpenModalTipo] = useState(false);
  const [openModalEditEspecialista, setOpenModalEditEspecialista] = useState(false);
  const [usuariosEspecialistas, setUsuariosEspecialistas] = useState([]);
  const [especialistaSeleccionado, setEspecialistaSeleccionado] = useState({});
  const [vista, setVista] = useState(false);
  const [asignada, setAsignada] = useState(false);
  const [tiposAtencion, setTiposAtencion] = useState(false);
  const [tipoAtencionSeleccionado, setTipoAtencionSeleccionado] =
    useState(false);
  const [verDetalle, setVerDetalle] = useState(false);

  function marcarVista(alertaSeleccionada) {
    axios
      .post(apiURl + "/alertas/marcarAlertaComoVista", {
        idAlerta: alertaSeleccionada.id,
        emailUsuarioVisto: usuarioLogeado.email,
      })
      .then((res) => {
        setVista(true);
        metObtienAlertas();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function asignarEspecialista() {
    axios
      .post(apiURl + "/especialistas/asignarEstudianteAespecialista", {
        especialistaId: especialistaSeleccionado.id,
        alertaId: alerta.id,
      })
      .then((res) => {
        Swal.fire({
          text: "Asignación realizada correctamente",
          icon: "success",
        });
        setOpenModal(false);
        setAsignada(true);
        metObtienAlertas();
        setVista(true);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          text: err.request.response,
          icon: "error",
        });
      });
  }

  function asignarNuevoEspecialista() {
    axios
      .post(apiURl + "/especialistas/editarEspecialistaAsignado", {
        especialistaId: especialistaSeleccionado.id,
        alertaId: alerta.id,
      })
      .then((res) => {
        Swal.fire({
          text: "Asignación realizada correctamente",
          icon: "success",
        });
        setOpenModalEditEspecialista(false);
        setAsignada(true);
        metObtienAlertas();
        setVista(true);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          text: err.request.response,
          icon: "error",
        });
      });
  }


  function asignarTipoAtencion() {
    axios
      .post(apiURl + "/alertas/asignarTipoAtencion", {
        tipoAtencionId: tipoAtencionSeleccionado.id,
        alertaId: alerta.id,
      })
      .then((res) => {
        Swal.fire({
          text: "Asignación realizada correctamente",
          icon: "success",
        });
        setOpenModalTipo(false);
        setAsignada(false);
        metObtienAlertas();
        setVista(true);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          text: err.request.response,
          icon: "error",
        });
      });
  }

  function finalizarAlerta() {
    axios
      .post(apiURl + "/alertas/finalizarAlerta", {
        idAlerta: alerta.id,
        userName: usuarioLogeado.email,
      })
      .then((res) => {
        metObtienAlertas();
      })
      .catch((err) => {
        Swal.fire({ text: "Error al finalizar la alerta", icon: "error" });
      });
  }

  useEffect(() => {
    setVista(alerta.vista);
    setAsignada(alerta.asignada);
    console.log(alerta.especialistaAsignado);
    axios
      .get(apiURl + "/usuarios/obtenerUsuariosEspecialistas")
      .then((res) => {
        setUsuariosEspecialistas(res.data.usuarios);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener especialistas", icon: "error" });
      });

    axios
      .get(apiURl + "/tipoAtencionApi/obtenerTodosLosTiposAtencion")
      .then((res) => {
        setTiposAtencion(res.data.tiposAtencion);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener tipo de atención", icon: "error" });
      });
  }, []);

  return (
    <>
      <Card>
        <div className={alerta.fechaFinalizacion ? "block" : "hidden"}>
          <HiCheckCircle color="green" size="2em" />
        </div>
        <div>
          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {alerta.estudiante.rut} - {alerta.estudiante.nombre}{" "}
            {alerta.estudiante.primerApellido}{" "}
            {alerta.estudiante.segundoApellido}
          </h5>
        </div>
        <div>
          <span className="text-slate-400">
            {" "}
            {moment(alerta.fachaIngreso).format("DD/MM/yyyy hh:ss")}
          </span>
        </div>
        <div>
          <p>{alerta.detalle}</p>
          <div className="m-3">
            <Badge
              icon={HiOutlineUserRemove}
              className={alerta.especialistaAsignado.length > 0 && !alerta.fechaCierreEspecialista ? "float-left mr-3" : "hidden"}
              color="warning"
              onClick={() => setOpenModalEditEspecialista(true)}
              href="#"
            >
              Cambiar especialista
            </Badge>
            <Badge
              icon={HiChevronDoubleRight}
              className={!verDetalle ? "float-left mr-3" : "hidden"}
              color="warning"
              onClick={() => setVerDetalle(true)}
              href="#"
            >
              Ver detalle
            </Badge>
            <Badge
              icon={HiChevronDoubleLeft}
              className={verDetalle ? "w-20" : "hidden"}
              color="warning"
              onClick={() => setVerDetalle(false)}
              href="#"
            >
              Ocultar
            </Badge>
          </div>
          <br></br>
          <div className={verDetalle ? "pt-3 " : "hidden"}>
            {alerta.asignada ? (
              <div>
                <Badge className="w-6/12" color="purple" size="sm">
                  Alerta asignada al especialista{" "}
                  {
                    alerta.especialistaAsignado[
                      alerta.especialistaAsignado.length - 1
                    ].username
                  }
                  , el{" "}
                  {moment(alerta.fechaAsignacion).format("DD/MM/yyyy hh:mm")}
                </Badge>
              </div>
            ) : (
              ""
            )}

            {alerta.fechaCierreEspecialista ? (
              <div>
                <Badge color="purple" size="sm" className="mt-3 w-6/12">
                  Proceso cerrado por especialista{" "}
                  {alerta.usuarioEspecialistaCerro.username}, el{" "}
                  {moment(alerta.fechaCierreEspecialista).format(
                    "DD/MM/yyyy hh:mm"
                  )}
                </Badge>
              </div>
            ) : (
              ""
            )}

            {alerta.fechaFinalizacion ? (
              <div>
                <Badge color="purple" size="sm" className="mt-3 w-6/12">
                  Proceso finalizado por el usuario{" "}
                  {alerta.usuarioDaeFinalizo.username}, el{" "}
                  {moment(alerta.fechaFinalizacion).format("DD/MM/yyyy hh:mm")}
                </Badge>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="flex flex-col  mt-5 ">
            <Button.Group>
              <Button
                disabled={alerta.vista}
                onClick={() => marcarVista(alerta)}
                gradientDuoTone="greenToBlue"
              >
                <Bs1CircleFill className="mr-1" /> Marcar como visto
                <HiCheckCircle
                  className={alerta.vista ? "block" : "hidden"}
                  color="green"
                  size="2em"
                />
              </Button>
              <Button
                disabled={!alerta.vista || alerta.tipoAtencion?.id}
                onClick={() => setOpenModalTipo(true)}
                gradientDuoTone="greenToBlue"
              >
                <Bs2CircleFill className="mr-1" />
                Clasificar tipo apoyo
                <HiCheckCircle
                  className={
                    alerta.vista && alerta.tipoAtencion?.id ? "block" : "hidden"
                  }
                  color="green"
                  size="2em"
                />
              </Button>
              <Button
                disabled={
                  !alerta.tipoAtencion?.id ||
                  alerta.especialistaAsignado.length > 0
                }
                onClick={() => setOpenModal(true)}
                gradientDuoTone="greenToBlue"
              >
                <Bs3CircleFill className="mr-1" />
                Asignar especialista
                <HiCheckCircle
                  className={
                    alerta.tipoAtencion?.id &&
                    alerta.especialistaAsignado.length > 0
                      ? "block"
                      : "hidden"
                  }
                  color="green"
                  size="2em"
                />
              </Button>
              <Button
                disabled={
                  alerta.fechaFinalizacion ||
                  !alerta.asignada ||
                  alerta.fechaCierreEspecialista
                }
                gradientDuoTone="greenToBlue"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Bs4CircleFill className="mr-1" />
                Proceso de apoyo en curso
                <HiCheckCircle
                  className={
                    alerta.fechaCierreEspecialista ? "block" : "hidden"
                  }
                  color="green"
                  size="2em"
                />
              </Button>
              <Button
                disabled={
                  !alerta.fechaCierreEspecialista || alerta.fechaFinalizacion
                }
                onClick={finalizarAlerta}
                gradientDuoTone="greenToBlue"
              >
                <Bs5CircleFill className="mr-1" />
                Marcar como finalizado
                <HiCheckCircle
                  className={alerta.fechaFinalizacion ? "block" : "hidden"}
                  color="green"
                  size="2em"
                />
              </Button>
            </Button.Group>
          </div>
        </div>
      </Card>

      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Asignar especialista</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Seleccione un especialista para que este realice acciones de apoyo
              al estudiante que involucra esta alerta.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Dropdown
            label={
              especialistaSeleccionado.id
                ? especialistaSeleccionado.username
                : "Buscar especialista"
            }
          >
            {usuariosEspecialistas.length > 0 ? (
              usuariosEspecialistas.map((u) => (
                <Dropdown.Item
                  onClick={() => {
                    setEspecialistaSeleccionado(u);
                  }}
                >
                  {u.username}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item>No hay usuarios</Dropdown.Item>
            )}
          </Dropdown>
          <Button onClick={asignarEspecialista}>Asignar</Button>
          <Button
            color="gray"
            onClick={() => {
              setOpenModal(false);
              setEspecialistaSeleccionado({});
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openModalTipo} onClose={() => setOpenModalTipo(false)}>
        <Modal.Header>Categorizar tipo de apoyo</Modal.Header>
        <Modal.Body>
          <Dropdown label="Tipos de atención">
            {tiposAtencion.length > 0 ? (
              tiposAtencion.map((t) => (
                <Dropdown.Item
                  onClick={() => {
                    setTipoAtencionSeleccionado(t);
                  }}
                >
                  {t.nombre}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item>
                No existen tipos de atención registrados
              </Dropdown.Item>
            )}
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={asignarTipoAtencion}>Guardar</Button>
          <Button
            color="gray"
            onClick={() => {
              setOpenModalTipo(false);
              setTipoAtencionSeleccionado({});
            }}
          >
            {" "}
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openModalEditEspecialista} onClose={() => setOpenModalEditEspecialista(false)}>
        <Modal.Header>Editar especialista asignado</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Seleccione un especialista para que este realice acciones de apoyo
              al estudiante que involucra esta alerta.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Dropdown
            label={
              especialistaSeleccionado.id
                ? especialistaSeleccionado.username
                : "Buscar especialista"
            }
          >
            {usuariosEspecialistas.length > 0 ? (
              usuariosEspecialistas.map((u) => (
                <Dropdown.Item
                  onClick={() => {
                    setEspecialistaSeleccionado(u);
                  }}
                >
                  {u.username}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item>No hay usuarios</Dropdown.Item>
            )}
          </Dropdown>
          <Button onClick={asignarNuevoEspecialista}>Asignar nuevo especialista</Button>
          <Button
            color="gray"
            onClick={() => {
              setOpenModalEditEspecialista(false);
              setEspecialistaSeleccionado({});
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AcordionAlertas;

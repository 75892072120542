import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Swal from "sweetalert2"
import axios from "./../../interceptor/AxiosInterceptor"
import CardAmbito from "./CardAmbito"
import { Button } from "flowbite-react"
import {HiArrowSmLeft } from "react-icons/hi"
import { Link } from "react-router-dom"


const VerCategorizacion = ()=>{

    const {cateId,idDocente} = useParams()

    const [categorizacion,setCategorizacion] = useState([])
    const [ambitos,setAmbitos] = useState([])
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT


    useEffect(()=>{

        axios.get(apiURl+'/categorizaciones/obtenerCategorizacionPorId/'+cateId)
        .then(res=>{
            setCategorizacion(res.data.categorizacion)
            setAmbitos(res.data.ambitos)
        }).catch(err=>{
            Swal.fire({text:'Error al obtener categorización', icon:'error'})
        })
    },[])
    
    return <>
        <h4 className="text-2xl mt-5 text-center">Categorización docente</h4>
        <div className="flex flex-col pl-5 pr-5"> 
        {ambitos.length > 0 ? ambitos.map(a=><CardAmbito key={a.id} idCategoria={categorizacion.id} ambito={a}/>) 
               : <span className="text-sm italic text-center">No existen datos para presentar</span>}

        
        </div>
    </>

}

export default VerCategorizacion
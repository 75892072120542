import {
  TextInput,
  Button,
  Datepicker,
  ToggleSwitch,
  Dropdown,
  ListGroup,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";

const CrearDocente = () => {
  const [docente, setDocente] = useState({});
  const [tieneExperiencia, setTieneExperiencia] = useState(false);
  const [instituciones, setInstituciones] = useState([]);
  const [institucionesSeleccionadas, setInstitucionesSeleccionadas] = useState(
    []
  );

  const [archivosEstudios, setArchivosEstudios] = useState([]);


  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  function submitForm(event) {
    event.preventDefault();
  }

  function capturarFormulario(event) {
    setDocente({
      ...docente,
      [event.target.name]: event.target.value,
    });
  }

  function handleDateChangeIngreso(value) {
    setDocente({
      ...docente,
      ["fecIngreso"]:
        value.getDate() +
        "/" +
        (value.getMonth() + 1) +
        "/" +
        value.getFullYear(),
    });
  }

  function handleDateChangeNacimiento(value) {
    setDocente({
      ...docente,
      ["fecNacimiento"]:
        value.getDate() +
        "/" +
        (value.getMonth() + 1) +
        "/" +
        value.getFullYear(),
    });
  }

  function enviarForm() {
    docente.tieneExperiencia = tieneExperiencia;
    docente.institucionReferencia = institucionesSeleccionadas;

    axios
      .post(apiURl + "/docentes/guardarDocente", docente)
      .then((res) => {
        Swal.fire({ text: "Docente creado correctamente", icon: "success" });
        setDocente({
          rut: "",
          nombre: "",
          primerApellido: "",
          segundoApellido: "",
          fecIngreso: "",
          fecNacimiento: "",
          profesion: "",
          tieneExperiencia: false,
          aniosExperiencia: "",
          codprof: "",
          emailPersonal: "",
          emailInstitucional: "",
          celular: "",
          direccion: "",
          comuna: "",
          ciudad: ""
        });
        setTieneExperiencia(false);
        setInstitucionesSeleccionadas([]);
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }

  function seleccionarInstitucion(insti) {
    setInstitucionesSeleccionadas([...institucionesSeleccionadas, insti]);
  }

  function eliminarInstiSeleccionada(insti) {
    let index = institucionesSeleccionadas.indexOf(insti)
    institucionesSeleccionadas.splice(index, 1)
    setInstitucionesSeleccionadas([...institucionesSeleccionadas])

  }

  async function adjuntarArchivo(event) {
    let arch = event.target.files[0]
    const buffer = await arch.arrayBuffer();
    let byteArray = new Int8Array(buffer);

    let archivoEstudio = {
      nombre: event.target.files[0].name
    }

    setArchivosEstudios([...archivosEstudios, archivoEstudio])
  }


  useEffect(() => {
    axios
      .get(apiURl + "/institucionApi/obtenerTodasInstituciones")
      .then((res) => {
        setInstituciones(res.data.instituciones);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener instituciones", icon: "error" });
      });
  }, []);

  return (
    <>
      <h4 className="text-2xl mt-5 text-center">Crear docente</h4>
      <div className="mt-5 flex flex-col items-center justify-center">
        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="rut"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Rut
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="rut"
            className="w-96"
            name="rut"
            aria-label="Rut"
            type="text"
            placeholder="Rut"
            value={docente.rut}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="nombre"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre(s)
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="nombre"
            className="w-96"
            name="nombre"
            aria-label="Nombre completo"
            type="text"
            placeholder="Nombre completo"
            value={docente.nombre}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="primerApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Primer Apellido
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="primerApellido"
            className="w-96"
            name="primerApellido"
            aria-label="Primer Apellido"
            type="text"
            placeholder="Primer Apellido"
            value={docente.primerApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="segundoApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Segundo Apellido
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="segundoApellido"
            className="w-96"
            name="segundoApellido"
            aria-label="Segundo Apellido"
            type="text"
            placeholder="Segundo Apellido"
            value={docente.segundoApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="fecIngreso"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Fecha de ingreso al establecimiento
            </label>
          </div>
          <Datepicker
            onSelectedDateChanged={handleDateChangeIngreso}
            name="fecIngreso"
            labelClearButton="Limpar"
            labelTodayButton="Hoy"
            language="cl-CL"
            value={docente.fecIngreso}
          />
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="fecNacimiento"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Fecha de nacimiento
            </label>
          </div>

          <Datepicker
            onSelectedDateChanged={handleDateChangeNacimiento}
            name="fecNacimiento"
            labelClearButton="Limpar"
            labelTodayButton="Hoy"
            language="cl-CL"
            value={docente.fecNacimiento}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailInstitucional"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico Institucional
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="emailInstitucional"
            className="w-96"
            name="emailInstitucional"
            aria-label="Correo electrónico"
            type="text"
            placeholder="Correo electrónico"
            value={docente.emailInstitucional}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailProfesional"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico Personal
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="emailPersonal"
            className="w-96"
            name="emailPersonal"
            aria-label="Correo electrónico Personal"
            type="text"
            placeholder="Correo electrónico Personal"
            value={docente.emailPersonal}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="profesion"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Profesión
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="profesion"
            className="w-96"
            name="profesion"
            aria-label="Profesión"
            type="text"
            placeholder="Profesión"
            value={docente.profesion}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="codprof"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Código Profesor
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="codprof"
            className="w-96"
            name="codprof"
            aria-label="codprof"
            type="text"
            placeholder="codprof"
            value={docente.codprof}
          />
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="celular"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Celular Personal
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="celular"
            className="w-96"
            name="celular"
            aria-label="Celular"
            type="text"
            placeholder="Celular"
            value={docente.celular}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="direccion"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Dirección
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="direccion"
            className="w-96"
            name="direccion"
            aria-label="direccion"
            type="text"
            placeholder="direccion"
            value={docente.direccion}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="direccion"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Comuna
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="comuna"
            className="w-96"
            name="comuna"
            aria-label="comuna"
            type="text"
            placeholder="comuna"
            value={docente.comuna}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="ciudad"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Ciudad
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="ciudad"
            className="w-96"
            name="ciudad"
            aria-label="ciudad"
            type="text"
            placeholder="ciudad"
            value={docente.ciudad}
          />



          <div className="flex items-center gap-2 mt-5">
            <ToggleSwitch
              checked={tieneExperiencia}
              label="Experiencia en docencia"
              onChange={setTieneExperiencia}
            />
          </div>

          <div className="flex items-center gap-2 mt-5">
            <TextInput
              onChange={capturarFormulario}
              id="aniosExperiencia"
              className={
                docente.tieneExperiencia || tieneExperiencia ? "w-96" : "hidden"
              }
              name="aniosExperiencia"
              aria-label="aniosExperiencia"
              type="text"
              placeholder="Años de experiencia"
              value={docente.aniosExperiencia}
            />
          </div>

          <div
            className={
              docente.tieneExperiencia || tieneExperiencia
                ? "flex items-center gap-2 mt-5"
                : "hidden"
            }
          >
            <Dropdown color="gray" label="Instituciones de referencia">
              {instituciones.length > 0
                ? instituciones.map((i) => (
                  <Dropdown.Item
                    key={i.id}
                    onClick={() => seleccionarInstitucion(i)}
                  >
                    {i.nombre}
                  </Dropdown.Item>
                ))
                : ""}
            </Dropdown>
          </div>

          <div className={docente.tieneExperiencia || tieneExperiencia ? 'flex flex-wrap gap-2 mt-3' : 'hidden'}>
            <ListGroup>
              {institucionesSeleccionadas.length > 0 ? institucionesSeleccionadas.map(i =>
                <ListGroup.Item key={i.id}>{i.nombre} <Button onClick={() => eliminarInstiSeleccionada(i)} color="red" className="ml-2" size="xs">x</Button></ListGroup.Item>) : ''}
            </ListGroup>
          </div>

          <div className="mt-10">
            <Button onClick={enviarForm} type="submit">
              <HiOutlineCheckCircle className="mr-2 h-5 w-5" />
              Guardar docente
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CrearDocente;

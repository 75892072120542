import { TextInput, Button } from "flowbite-react";
import { useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi"
import Swal from "sweetalert2"
import axios from './../../interceptor/AxiosInterceptor'


const CrearArea = () => {
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    //el const es una variable que no se puede cambiar
    const [area, setArea] = useState({

    })

    function capturarFormulario(event) {
        setArea({
            ...area,
            [event.target.name]: event.target.value
        })

    }

    function submitForm(event) {
        event.preventDefault()

    }

    function enviarForm() {
        console.log(area)

        axios.post(apiURl + "/areaApi/guardarArea", area)
            .then(res => {
                Swal.fire({ text: 'Área creado', icon: 'success' })
                setArea({
                    nombreArea: '',
                })
            })
            .catch(err => {
                Swal.fire({ text: err.response.data, icon: 'error' })
            })

    }

    return <>

        <h4 className="text-2xl mt-5 text-center">Crear área para derivar estudiantes</h4>
        <div className="mt-10 flex flex-col items-center justify-center">
            <form onSubmit={submitForm}>

                <div className="flex items-center justify-between mt-5">
                    <label htmlFor="nombreArea" className="block text-sm font-medium leading-6 text-gray-900">Nombre Area</label>
                </div>

                <TextInput onChange={capturarFormulario} id="nombreArea" className="w-96" name="nombreArea" aria-label="nombre Area"
                    type="text" placeholder="Nombre Area" value={area.nombreArea} />

                <div className="mt-10">
                    <Button onClick={enviarForm} type="submit"><HiOutlineCheckCircle
                        className="mr-2 h-5 w-5" />Guardar Area</Button>
                </div>
            </form>
        </div>

    </>

}


export default CrearArea;
import { Link } from "react-router-dom"
import { Button } from "flowbite-react"
import {HiOutlinePlusCircle } from 'react-icons/hi'
import TablaInstituciones from "./TablaInstituciones"

const InstitucionesDash = ()=>{

    return <>
      <h3 className="text-xl text-center">Mantenedor de instituciones</h3>
            <Link to="/crearInstitucion">
                <Button className="ml-3">
                    <HiOutlinePlusCircle className="mr-2 h-5 w-5" /><p>Crear Institución</p>
                </Button>
            </Link>
            <TablaInstituciones />
    </>

}
    
export default InstitucionesDash
import { Button, Table} from "flowbite-react"
import { Link } from "react-router-dom"
import { HiTrash, HiPencilAlt} from "react-icons/hi"
import axios from './../../interceptor/AxiosInterceptor'
import Swal from "sweetalert2"



const ItemRowUsuario = ({usuario})=>{

    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT

    function eliminarUsuario(){
      Swal.fire({
        title: 'Está seguro de eliminar al usuario seleccionado ?',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(apiURl+'/usuarios/eliminarUsuario',usuario)
          .then(res=>{
            Swal.fire({text:'Se ha eliminado el usuario correctamente',icon:'success'})
          }).catch(err=>{
            Swal.fire({text:'Error a eliminar el usuario',icon:'error'})
          })

        }
      })
        
       
    }

    return <>
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
             {usuario.username}
          </Table.Cell>
          <Table.Cell>
             {usuario.email}
          </Table.Cell>
          <Table.Cell>
             {usuario.roles.map(r=>r.name+", ")}
          </Table.Cell>
          <Table.Cell>
            <div className="columns-2">
              <div className="w-0">
                  <Link
                  className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
                  to={'/editarUsuario/'+usuario.id}>
                  <Button><HiPencilAlt className="" /></Button>
                </Link>
              </div>
              <div className="w-0">
                 <Button color="failure" onClick={eliminarUsuario} ><HiTrash className="" /></Button>
              </div>
            </div>
          </Table.Cell>
        </Table.Row>
    </>

}

export default ItemRowUsuario

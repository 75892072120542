import { Table } from "flowbite-react"
import { Link } from "react-router-dom"

const RowItemProspecto = ({ prospecto }) => {


  return <>
    {prospecto ?
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {prospecto.nombres} {prospecto.primerApellido} {prospecto.segundoApellido}
        </Table.Cell>
        <Table.Cell>
          {prospecto.rut}
        </Table.Cell>
        <Table.Cell>
          <Link
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
            to={'/fichaProspecto/' + prospecto.id}>
            <p>
              Ver ficha prospecto
            </p>
          </Link>
        </Table.Cell>
      </Table.Row>
      : ''}
  </>
}

export default RowItemProspecto
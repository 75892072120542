import { Button, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "./../interceptor/AxiosInterceptor";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import Swal from "sweetalert2";

const ListaEstudiantesEnTratamiento = ()=>{

    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    const [alertasAsignadas,setAlertasAsignadas] = useState([])
    const { usuarioLogeado } = useContext(AuthContext);

    useEffect(()=>{
        axios.post(apiURl+'/estudiantes/obtenerEstudiantesEnTratamiento',{
            userName:usuarioLogeado.email
        }).then(res=>{
            console.log(res)
            setAlertasAsignadas(res.data.alertasAsignadas)
        }).catch(err=>{
            console.log(err)
        })
    },[])

    function cerrarProceso(alerta){

      Swal.fire({
        title: 'Está seguro de cerrar el proceso seleccionado ?',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
            axios.post(apiURl+'/alertas/cerrarProcesoEspecialista',{
                userName:usuarioLogeado.email,
                idAlerta:alerta.id
            }).then(res=>{
                setAlertasAsignadas(res.data.alertasAsignadas)
            }).catch(err=>{
                Swal.fire({text:'Error al intentar cerrar el proceso',icon:'error'})
            })
        }
      })

       

    }

    return <>
            <div className="text-center flex justify-center mt-10">
                <h6 className="text-blueGray-700 text-xl font-bold">
                    Estudiantes asignados
                </h6>
            </div>      
            <Table className="mt-5" hoverable>
                <Table.Head>
                    <Table.HeadCell>Rut</Table.HeadCell>
                    <Table.HeadCell>Nombre</Table.HeadCell>
                    <Table.HeadCell>Fecha Asignación</Table.HeadCell>
                    <Table.HeadCell>Acciones</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                {alertasAsignadas.length > 0 ? 
                alertasAsignadas.map(a=>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-slate-400">
                            {a.estudiante.rut}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-slate-400">
                            {a.estudiante.nombre} {a.estudiante.primerApellido} {a.estudiante.segundoApellido}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-slate-400">
                            {moment(a.fechaAsignacion).format('DD/MM/yyyy HH:mm')}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-slate-400">
                            <Link className="float-left mr-3" to={'/ingresarAccionApoyo/'+a.estudiante.id+'/'+a.id} ><Button disabled={a.fechaCierreEspecialista} size="xs">Registrar acciones de apoyo</Button></Link>
                            <Button disabled={a.fechaCierreEspecialista} onClick={()=>cerrarProceso(a)} className="float-left mr-3" size="xs">Cerrar proceso</Button>
                            <Link  to={'/fichaEstudiante/'+a.estudiante.id} ><Button size="xs">Ver ficha estudiantil</Button></Link>
                        </Table.Cell>
                </Table.Row>) :  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-slate-400 italic">
                    Realice una busqueda para presentar registros
                </Table.Cell>
                </Table.Row>}

                </Table.Body>
            </Table>
    </>
}

export default ListaEstudiantesEnTratamiento;
import { Button, Table, Label, TextInput } from "flowbite-react";
import { useState } from "react";
import Swal from "sweetalert2";
import axios from "./../interceptor/AxiosInterceptor";
import RowItemDocente from "./RowItemDocente";

const BuscadorDocentes = () => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  const [docentes, setDocentes] = useState([]);
  const [busquedaDocente, setBusquedaDocente] = useState({});

  function handleInput(event) {
    setBusquedaDocente({
      ...busquedaDocente,
      [event.target.name]: event.target.value,
    });
  }

  function submitForm(event) {
    event.preventDefault();
  }

  function buscarDocente() {
    axios
      .post(apiURl + "/docentes/buscarDocentes", busquedaDocente)
      .then((res) => {
        if (res.data.docentes && res.data.docentes.length > 0)
          setDocentes(res.data.docentes);
        else {
          setDocentes([]);
          Swal.fire({
            text: "No se han encontrado resultados en la búsqueda",
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        Swal.fire({ text: "Error al buscar docentes", icon: "error" });
      });
  }

  function buscarTodosLosDocentes() {
    axios
      .get(apiURl + "/docentes/obtenerTodosLosDocentes")
      .then((res) => {
        if (res.data.docentes && res.data.docentes.length > 0)
          setDocentes(res.data.docentes);
        else {
          setDocentes([]);
          Swal.fire({
            text: "No se han encontrado resultados en la búsqueda",
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        Swal.fire({ text: "Error al buscar docentes", icon: "error" });
      });
  }

  return (
    <>
      <section className=" py-1 bg-blueGray-50">
        <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-center">
                <h6 className="text-blueGray-700 text-xl font-bold">
                  Busqueda de docentes
                </h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={submitForm}>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="rut" value="Rut" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="rut"
                    name="rut"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className="">
                    <Label htmlFor="nombre" value="Nombre" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="nombre"
                    name="nombre"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="primerApellido" value="Primer Apellido" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="primerApellido"
                    name="primerApellido"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="segundoApellido" value="Segundo Apellido" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="segundoApellido"
                    name="segundoApellido"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className="block">
                    <Label htmlFor="email" value="Correo electrónico" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="email"
                    name="email"
                    type="text"
                  />
                </div>
                <br className="clear-both"></br>
                <div className="mt-5">
                  <Button className="float-left mr-3" onClick={buscarDocente}>
                    Buscar
                  </Button>
                  <Button onClick={buscarTodosLosDocentes}>
                    Buscar todos los docentes
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Table hoverable>
        <Table.Head>
          <Table.HeadCell>Nombre</Table.HeadCell>
          <Table.HeadCell>Rut</Table.HeadCell>
          <Table.HeadCell>Profesion</Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Ver ficha de docente</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {docentes.length > 0 ? (
            docentes.map((d) => <RowItemDocente key={d.id} docente={d} />)
          ) : (
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-slate-400 italic">
                Realice una busqueda para presentar registros
              </Table.Cell>
            </Table.Row>
          )}
          <RowItemDocente />
        </Table.Body>
      </Table>
    </>
  );
};

export default BuscadorDocentes;

import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {
  ADMIN,
  DOCENTE,
  ESPECIALISTA,
  DAE,
  JEFE_CARRERA,
  SEGIMIENTO,
} from "../util/Constantes";
import { Dropdown } from "flowbite-react";

const MenuPerfilado = () => {
  const { usuarioLogeado } = useContext(AuthContext);

  if (!usuarioLogeado) return <></>;

  if (!usuarioLogeado.roles) return <></>;

  const isAdmin = usuarioLogeado.roles.find((rol) => rol === ADMIN);
  const isDocente = usuarioLogeado.roles.find((rol) => rol === DOCENTE);
  const isEspecialista = usuarioLogeado.roles.find(
    (rol) => rol === ESPECIALISTA
  );
  const isDae = usuarioLogeado.roles.find((rol) => rol === DAE);

  const isSegimiento = usuarioLogeado.roles.find((rol) => rol === SEGIMIENTO);

  const isJefeCarrera = usuarioLogeado.roles.find(
    (rol) => rol === JEFE_CARRERA
  );

  if (isAdmin)
    return (
      <>
        <div>
          <Dropdown arrowIcon={false} inline label="Crear datos">
            <Dropdown.Item>
              <Link to="/crearEstudiante">Crear Estudiante</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/crearDocente">Crear Docente</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/crearProspecto">Crear Prospecto</Link>
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div>
          <Dropdown arrowIcon={false} inline label="Buscadores">
            <Dropdown.Item>
              <Link to="/buscarEstudiante">Buscar Estudiantes</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/buscarDocente">Buscar Docentes</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/buscadorProspectos">Buscar Prospecto</Link>
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div>
          <Dropdown arrowIcon={false} inline label="Estudiantes">
            <Dropdown.Item>
              <Link to="/derivarAlertaDae">Derivar Alerta a DAE</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/verTodosProcesosApoyo">
                Estudiantes en acompañamiento
              </Link>
            </Dropdown.Item>
          </Dropdown>
        </div>
        <Link to="/admin">Administración</Link>
        <Link to="/verTodasCategorizaciones">
          Resumen categorización docente
        </Link>
      </>
    );

  if (isJefeCarrera)
    return (
      <>
        <Link to="/">Dashboard</Link>
        <Link to="/buscarEstudiante">Buscar Estudiantes</Link>
        <Link to="/buscarDocente">Buscar Docentes</Link>
        <Link to="/verTodasCategorizaciones">
          Resumen categorización docente
        </Link>
        <Link to="/verTodosProcesosApoyo">
          Ver estudiantes en acompañamiento
        </Link>
      </>
    );

  if (isDocente)
    return (
      <>
        <Link to="/buscarEstudiante">Buscar Estudiantes</Link>
        <Link to="/asignaturasDocente">Mis Asignaturas</Link>
        <Link to="/derivarAlertaDae">Derivar Alerta a DAE</Link>
      </>
    );

  if (isDae)
    return (
      <>
        <Link to="/">Dashboard</Link>
        <Link to="/bandejaAlertas">Alertas</Link>
        <div>
          <Dropdown arrowIcon={false} inline label="Crear datos">
            <Dropdown.Item>
              <Link to="/crearEstudiante">Crear Estudiante</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/crearDocente">Crear Docente</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/crearProspecto">Crear Prospecto</Link>
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div>
          <Dropdown arrowIcon={false} inline label="Buscadores">
            <Dropdown.Item>
              <Link to="/buscarEstudiante">Buscar Estudiantes</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/buscarDocente">Buscar Docentes</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/buscadorProspectos">Buscar Prospecto</Link>
            </Dropdown.Item>
          </Dropdown>
        </div>
        <Link to="/verTodosProcesosApoyo">
          Ver estudiantes en acompañamiento
        </Link>
      </>
    );

  if (isEspecialista)
    return (
      <>
        <Link to="/verProcesosApoyo">Estudiantes Asignados</Link>
      </>
    );

  if (isSegimiento)
    return (
      <>
        <Link to="/">Dashboard</Link>
        <Link to="/verTodosProcesosApoyo">
          Ver estudiantes en acompañamiento
        </Link>
        <Link to="/verTodasCategorizaciones">
          Resumen categorización docente
        </Link>
      </>
    );
};

export default MenuPerfilado;

import { useEffect, useContext, useState } from "react";
import axios from "./../interceptor/AxiosInterceptor";
import { AuthContext } from "../../context/AuthContext";
import { Table } from "flowbite-react";
import { ListGroup } from "flowbite-react";
import { Link } from "react-router-dom";

const EstudiantesDerivados = () => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const { usuarioLogeado } = useContext(AuthContext);
  const [listaEstu, setListaEstu] = useState([]);

  useEffect(() => {
    if (usuarioLogeado) {
      axios
        .post(apiURl + "/usuarios/obtenerEstudiantesAsignados", {
          username: usuarioLogeado.email,
        })
        .then((res) => {
          setListaEstu(res.data.estudiantes);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <h6 className="text-blueGray-700 text-xl font-bold mt-10">
      {listaEstu?.length} Estudiantes Asignados 
      </h6>

      <div className="flex">
        <ListGroup className="w-full">
          {listaEstu.length > 0
            ? listaEstu.map((e) => <ListGroup.Item><Link to={'/fichaEstudiante/'+e.id}>{e.rut} - {e.nombre} {e.primerApellido} {e.segundoApellido}</Link></ListGroup.Item>)
            : ""}
        </ListGroup>
      </div>
    </>
  );
};

export default EstudiantesDerivados;

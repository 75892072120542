import { AllowedAccess } from "react-permission-role";
import { DAE, SEGIMIENTO, JEFE_CARRERA} from "../util/Constantes";
import HomeDae from "./HomeDae";

const Home = () => {
  return (
    <>
      <AllowedAccess roles={[DAE]}>
        <HomeDae />
      </AllowedAccess>
      <AllowedAccess roles={[SEGIMIENTO]}>
        <HomeDae />
      </AllowedAccess>
      <AllowedAccess roles={[JEFE_CARRERA]}>
        <HomeDae />
      </AllowedAccess>
    </>
  );
};

export default Home;

import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from './../../interceptor/AxiosInterceptor'
import { Button, TextInput } from 'flowbite-react'
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import { HiArrowSmLeft, HiOutlineCheckCircle } from "react-icons/hi"


const EditarRole = () => {

    const [role, setRole] = useState([])
    const { idRole } = useParams()
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT

    function submitForm(event) {
        event.preventDefault()
    }

    function capturarFormulario(event) {
        setRole({
            ...role,
            [event.target.name]: event.target.value

        })
    }

    function enviarForm() {
        axios.post(apiURl + "/rolesApi/guardarRole", role)
            .then(res => {
                Swal.fire({ text: 'Role creado', icon: 'success' })
                setRole({
                    name: ''
                })
            })
            .catch(err => {
                Swal.fire({ text: err.response.data, icon: 'error' })
            })
    }


    useEffect(() => {

        axios.get(apiURl + '/rolesApi/obtenerRole/' + idRole)
            .then(res => {
                setRole(res.data.role)
            })
            .catch(err => {
                Swal.fire({ 'icon': 'error', 'text': 'Error al buscar los roles' })
            })


    }, [])

    return <>
        <h4 className="text-2xl mt-5 text-center">{role.name}</h4>
        <div className="mt-10 flex flex-col items-center justify-center">
            <form onSubmit={submitForm}>
                <div className="flex items-center justify-between">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Nombre Role</label>
                </div>
                <TextInput id="name" onChange={capturarFormulario} name="name" aria-label="Nombre Role"
                    type="text" placeholder="Nombre Role" value={role.name}
                    className="w-96" />

                <div className="columns-2 mt-10">
                    <div>
                        <Button onClick={enviarForm} type="submit"><HiOutlineCheckCircle className="mr-2 h-5 w-5" />Guardar</Button>
                    </div>
                    <div>
                        <Link to="/admin">
                            <Button type="submit"><HiArrowSmLeft className="mr-2 h-5 w-5" />Volver</Button>
                        </Link>
                    </div>
                </div>

            </form>
        </div>
    </>

}
export default EditarRole;
import { TextInput } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import axios from "./../interceptor/AxiosInterceptor";
import moment from "moment";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";

const ListaTodosEstudiantesAsignados = () => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const columns = [
    {
      name: "Rut",
      selector: (row) => row.alerta?.estudiante?.rut,
      width: "9%",
    },
    {
      name: "Nombre",
      selector: (row) =>
        row.alerta?.estudiante?.nombre +
        " " +
        row.alerta?.estudiante?.primerApellido +
        " " +
        row.alerta?.estudiante?.segundoApellido,
      sortable: true,
    },
    {
      name: "Estado actual del proceso",
      selector: (row) =>
        row.alerta.estadoActual,
      sortable: true,
    }
    ,
    {
      name: "Fecha Asignación",
      selector: (row) =>
        moment(row.alerta?.fechaAsignacion).format("DD/MM/yyyy HH:mm"),
      sortable: true,
      width:'13%'
    },
    {
      name: "Especialista asignado",
      selector: (row) => row.alerta?.especialistaAsignado[0]?.username,
    },
    {
      name: "Actividades registradas",
      selector: (row) => (row.accionesApoyo ? row.accionesApoyo.length : 0),
      sortable: true,
    },
    {
      name: "Acciones",
      selector: (row) => (
        <>
          <Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" to={"/fichaEstudiante/" + row.alerta?.estudiante.id}>
            Ver ficha estudiantil
          </Link>
        </>
      ),
    },
  ];

  function clickHandler() {}

  const [data, setData] = useState([]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <TextInput
        onChange={(e) => {
          setFilterText(e.target.value);
          if (!e.target.value) {
            traerDatos();
          } else {
            let dataFilter = data.filter(
              (a) =>
                a.estudiante &&
                a.estudiante.nombre
                  .toLowerCase()
                  .includes(filterText.toLowerCase())
            );
            if (dataFilter && dataFilter.length > 0) setData(dataFilter);
          }
        }}
      />
    );
  }, [filterText, data]);

  function traerDatos() {
    axios
      .get(apiURl + "/estudiantes/obtenerTodosEstudiantesEnTratamiento")
      .then((res) => {
        console.log(res.data.procesos)
        setData(res.data.procesos);
      })
      .catch((err) => {
        Swal.fire({
          text: "Error al obtener estudianes en procesos de ayuda o acompañamiento",
        });
      });
  }

  useEffect(() => {
    traerDatos();
  }, []);

  return (
    <>
      <div className="text-center flex justify-center mt-10">
        <h6 className="text-blueGray-700 text-xl font-bold">
          Estudianes en procesos de ayuda o acompañamiento
        </h6>
      </div>
      <DataTable
        columns={columns}
        data={data}
        subHeader
        pagination
        subHeaderComponent={subHeaderComponentMemo}
      />
    </>
  );
};

export default ListaTodosEstudiantesAsignados;

import { TextInput, Button, Datepicker, Radio, Label } from "flowbite-react";
import { useState, useEffect } from "react";
import { HiOutlineCheckCircle, HiArrowSmLeft } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "./../interceptor/AxiosInterceptor";

const EditarProspecto = () => {
  const [prospecto, setProspecto] = useState({});

  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  const { idProspecto } = useParams();

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    setProspecto({
      ...prospecto,
      [event.target.name]: event.target.value,
    });
  };

  function submitForm(event) {
    event.preventDefault();
  }

  function capturarFormulario(event) {
    setProspecto({
      ...prospecto,
      [event.target.name]: event.target.value,
    });
  }

  function handleDateChange(value) {
    setProspecto({
      ...prospecto,
      ["fecNacimiento"]:
        value.getDate() +
        "/" +
        (value.getMonth() + 1) +
        "/" +
        value.getFullYear(),
    });
  }

  function enviarForm() {
    console.log(prospecto);

    axios
      .post(apiURl + "/prospectos/guardarProspecto", prospecto)
      .then((res) => {
        Swal.fire({ text: "Prospecto creado", icon: "success" });
        setProspecto({
          rut: "",
          nombres: "",
          primerApellido: "",
          segundoApellido: "",
          fecNacimiento: "",
          genero: "",
          emailProspecto: "",
        });
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }

  useEffect(() => {
    axios
      .get(apiURl + "/prospectos/obtenerProspectoxId/" + idProspecto)
      .then((res) => {
        setProspecto(res.data.prospecto);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: "Error al buscar datos del prospecto",
        });
      });
  }, []);

  return (
    <>
      <h4 className="text-2xl mt-5">
        Editar prospecto {prospecto.nombres} {prospecto.primerApellido}{" "}
        {prospecto.segundoApellido}
      </h4>

      <div className="mt-10 flex flex-col items-center justify-center">
        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="rut"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Rut
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="rut"
            className="w-96"
            name="rut"
            aria-label="Rut"
            type="text"
            placeholder="Rut"
            value={prospecto.rut}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="nombres"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre(s)
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="nombres"
            className="w-96"
            name="nombres"
            aria-label="Nombres"
            type="text"
            placeholder="Nombres"
            value={prospecto.nombres}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="primerApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Primer Apellido
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="primerApellido"
            className="w-96"
            name="primerApellido"
            aria-label="primer Apellido"
            type="text"
            placeholder="Primer Apellido"
            value={prospecto.primerApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="segundoApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Segundo Apellido
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="segundoApellido"
            className="w-96"
            name="segundoApellido"
            aria-label="segundo Apellido"
            type="text"
            placeholder="Segundo Apellido"
            value={prospecto.segundoApellido}
          />

          <fieldset>
            <div>
              <div className="flex items-center gap-2">
                <Radio
                  id="femenino"
                  name="genero"
                  value="Femenino"
                  checked={prospecto.genero === "femenino"}
                  onChange={handleOptionChange}
                />
                <Label htmlFor="femenino">Femenino</Label>
              </div>

              <div className="flex items-center gap-2">
                <Radio
                  id="masculino"
                  name="genero"
                  value="Masculino"
                  checked={prospecto.genero === "masculino"}
                  onChange={handleOptionChange}
                />
                <Label htmlFor="masculino">Masculino</Label>
              </div>
            </div>
          </fieldset>

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="fechaNacimiento"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Fecha Nacimiento
            </label>
          </div>

          <Datepicker
            onSelectedDateChanged={handleDateChange}
            id="fecNacimiento"
            name="fecNacimiento"
            value={prospecto.fecNacimiento}
            labelClearButton="Limpiar"
            labelTodayButton="Hoy"
            language="cl-CL"
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailProspecto"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="emailProspecto"
            className="w-96"
            name="emailProspecto"
            aria-label="email Prospecto"
            type="text"
            placeholder="Email Prospecto"
            value={prospecto.emailProspecto}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailProspecto"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Celular
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="celular"
            className="w-96"
            name="celular"
            aria-label="celular Prospecto"
            type="text"
            placeholder="celular Prospecto"
            value={prospecto.celular}
          />

          <div className="columns-2 mt-10">
            <div>
              <Button onClick={enviarForm} type="submit">
                <HiOutlineCheckCircle className="mr-2 h-5 w-5" />
                Guardar cambios
              </Button>
            </div>

            <div>
              <Link to={"/fichaProspecto/" + prospecto.id}>
                <Button type="submit">
                  <HiArrowSmLeft className="mr-2 h-5 w-5" />
                  Volver
                </Button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditarProspecto;

import { Button, ListGroup } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "./../../interceptor/AxiosInterceptor";
import { HiOutlineTrash } from "react-icons/hi";
import Swal from "sweetalert2";

const CategorizarDocente = () => {
  const { idDocente } = useParams();
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  const [categorizaciones, setCategorizaciones] = useState([]);

  function submitForm(event) {
    event.preventDefault();
  }

  useEffect(() => {
    axios
      .get(
        apiURl + "/categorizaciones/obtenerCategorizacionesDocente/" + idDocente
      )
      .then((res) => {
        setCategorizaciones(res.data.categorizaciones);
      })
      .catch((err) => {});
  }, []);

  function crearCategorizacion() {
    axios
      .get(apiURl + "/categorizaciones/crearCategorizacion/" + idDocente)
      .then((res) => {
        setCategorizaciones(res.data.categorizaciones);
        Swal.fire({
          text: "Categorización creada correctamente",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({ text: "Error al crear categorización", icon: "error" });
      });
  }

  function eliminatCategorizacion(cate) {
    Swal.fire({
      title: "Está seguro de eliminar la categorización seleccionadoa?",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(apiURl + "/categorizaciones/eliminarCategorizacionDocente", {
            idCate: cate.id,
            idDocente: idDocente,
          })
          .then((res) => {
            setCategorizaciones(res.data.categorizaciones);
            Swal.fire({
              text: "Categorización eliminado correctamente",
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({ text: err.response.data, icon: "error" });
          });
      }
    });
  }

  return (
    <>
      <h4 className="text-2xl mt-5 text-center">
        Categorizar docente
      </h4>
      <div className="flex flex-col pl-5 pr-5">
        <form onSubmit={submitForm}>
          <div className="mt-10 mr-3">
            <Button size="xs" onClick={crearCategorizacion} type="submit">
              Crear Categorización
            </Button>
          </div>
          <ListGroup className="mt-5">
            {categorizaciones.length > 0 ? (
              categorizaciones.map((cate) => (
                <div className="columns-2">
                  <div>
                    <Link
                      to={"/verCategorizacion/" + cate.id + "/" + idDocente}
                    >
                      <ListGroup.Item>{cate.anio} - {cate.correlativo}</ListGroup.Item>
                    </Link>
                  </div>
                  <div className="flex justify-end pr-3">
                    <Button
                      size="xs"
                      onClick={() => eliminatCategorizacion(cate)}
                      type="submit"
                      color="failure"
                    >
                      <HiOutlineTrash className="mr-2 h-5 w-5" />
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <span className="text-sm italic text-center">
                No existen datos para presentar
              </span>
            )}
          </ListGroup>
        </form>
      </div>
    </>
  );
};

export default CategorizarDocente;

import { Button, Label, Spinner } from "flowbite-react";
import { HiOutlinePlusCircle } from "react-icons/hi";
import axios from "./../../interceptor/AxiosInterceptor";
import { useState } from "react";
import Swal from "sweetalert2";

const CargarDatos = () => {
  const [periodos, setPeriodos] = useState([]);
  const [estudiantes, setEstudiantes] = useState([]);
  const [codigoMatriculaxRut, setCodigoMatriculaxRut] = useState([]);
  const [datosAcademicosEstudiante, setDatosAcademicosEstudiante] = useState(
    []
  );
  const [docente, setDocente] = useState([]);
  const [cargaAcademicaDocente, setCargaAcademicaDocente] = useState([]);
  const [cargaAcademica, setCargaAcademica] = useState([]);
  const [sede, setSede] = useState([]);
  const [carrera, setCarrera] = useState([]);
  const [cargando,setCargando] = useState(false)

  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  function submitForm(event) {
    event.preventDefault();
  }

  function handleInput(event) {
    setPeriodos({
      ...periodos,
      [event.target.name]: event.target.value,
    });

    setEstudiantes({
      ...estudiantes,
      [event.target.name]: event.target.value,
    });

    setCodigoMatriculaxRut({
      ...codigoMatriculaxRut,
      [event.target.name]: event.target.value,
    });

    setDatosAcademicosEstudiante({
      ...datosAcademicosEstudiante,
      [event.target.name]: event.target.value,
    });

    setDocente({
      ...docente,
      [event.target.name]: event.target.value,
    });

    setCargaAcademicaDocente({
      ...cargaAcademicaDocente,
      [event.target.name]: event.target.value,
    });

    setCargaAcademica({
      ...cargaAcademica,
      [event.target.name]: event.target.value,
    });

    setSede({
      ...sede,
      [event.target.name]: event.target.value,
    });

    setCarrera({
      ...carrera,
      [event.target.name]: event.target.value,
    });
  }

  function obtenerPeriodos() {
    setCargando(true)
    axios
      .get(apiURl + "/cargarDatosUmas/obtenerTodosLosPeriodos")
      .then((res) => {
        setPeriodos(res.data.periodos);
        Swal.fire({ text: "Cargra exitosa", icon: "success" });
       
      })
      .catch((error) => {
        Swal.fire({ text: "Error en obtener los periodos", icon: "error" });
      }).finally(()=>{
        setCargando(false)
      });
  }

  function obtenerEstudiantes() {
    setCargando(true)
    axios
      .get(apiURl + "/cargarDatosUmas/obtenerTodosLosEstudiantes")
      .then((res) => {
        setEstudiantes(res.data.estudiantes);
        Swal.fire({ text: "Cargra exitosa", icon: "success" });
      })
      .catch((error) => {
        Swal.fire({ text: "Error en obtener los estudiantes", icon: "error" });
      }).finally(()=>{
        setCargando(false)
      });
  }

  function obtenerCodigosMatriculaxRut() {
    setCargando(true)
    axios
      .get(apiURl + "/cargarDatosUmas/obtenerCodigosMatriculaxRut")
      .then((res) => {
        setCodigoMatriculaxRut(res.data.codigoMatriculaxRut);
        Swal.fire({ text: "Cargra exitosa", icon: "success" });
      })
      .catch((error) => {
        Swal.fire({
          text: "Error en obtener los codigo de matricula por rut",
          icon: "error",
        });
      }).finally(()=>{
        setCargando(false)
      });
  }

  function obtenerTodosLosDocentes() {
    setCargando(true)
    axios
      .get(apiURl + "/cargarDatosUmas/obtenerTodosLosDocentes")
      .then((res) => {
        setDocente(res.data.docente);
        Swal.fire({ text: "Cargra exitosa", icon: "success" });
      })
      .catch((error) => {
        Swal.fire({
          text: "Error en obtener los datos del docente",
          icon: "error",
        });
      }).finally(()=>{
        setCargando(false)
      });
  }

  function obtenerCargaAcademicaDocente() {
    setCargando(true)
    axios
      .get(apiURl + "/cargarDatosUmas/obtenerCargaAcademicaDocente")
      .then((res) => {
        setCargaAcademica(res.data.cargaAcademica);
        Swal.fire({ text: "Cargra exitosa", icon: "success" });
      })
      .catch((error) => {
        Swal.fire({
          text: "Error en obtener los datos academicos del docente",
          icon: "error",
        });
      }).finally(()=>{
        setCargando(false)
      });
  }

  function obtenerCargaAcademicaEstudiante() {
    setCargando(true)
    axios
      .get(apiURl + "/cargarDatosUmas/obtenerCargaAcademicaEstudiante")
      .then((res) => {
        setCargaAcademica(res.data.cargaAcademica);
        Swal.fire({ text: "Cargra exitosa", icon: "success" });
      })
      .catch((error) => {
        Swal.fire({
          text: "Error en obtener los datos academicos",
          icon: "error",
        });
      }).finally(()=>{
        setCargando(false)
      });
  }

  function obtenerSede() {
    setCargando(true)
    axios
      .get(apiURl + "/cargarDatosUmas/obtenerSede")
      .then((res) => {
        setSede(res.data.sede);
        Swal.fire({ text: "Cargra exitosa", icon: "success" });
      })
      .catch((error) => {
        Swal.fire({ text: "Error en obtener las sedes", icon: "error" });
      }).finally(()=>{
        setCargando(false)
      });
  }

  function obtenerCarreras() {
    setCargando(true)
    axios
      .get(apiURl + "/cargarDatosUmas/obtenerCarreras")
      .then((res) => {
        setCarrera(res.data.carrera);
        Swal.fire({ text: "Cargra exitosa", icon: "success" });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({ text: "Error en obtener las carreras", icon: "error" });
      }).finally(()=>{
        setCargando(false)
      });;
  }

  return (
    <>
      <section className=" py-1 bg-blueGray-50">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-center">
              <h6 className="text-blueGray-700 text-xl font-bold">
                Carga de Datos
              </h6>
            </div>
          </div>
          {cargando ?  <div className="text-center" >
            <Spinner  size="xl" aria-label="Center-aligned spinner example" />
          </div>: <></> }
         

          <form onSubmit={submitForm}>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <Label htmlFor="periodos" value="períodos" />
              <Button className="ml-3" onClick={obtenerPeriodos}>
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" />
                <p>Cargar períodos</p>
              </Button>

              <Label htmlFor="estudiantes" value="Estudiantes" />
              <Button className="ml-3" onClick={obtenerEstudiantes}>
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" />
                <p>Cargar Estudiantes</p>
              </Button>

              <Label
                htmlFor="codigoMatriculaxRut"
                value="Matrículas por rut"
              />
              <Button className="ml-3" onClick={obtenerCodigosMatriculaxRut}>
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" />
                <p>Cargar matrículas por Rut</p>
              </Button>

              <Label htmlFor="docente" value="Docente" />
              <Button className="ml-3" onClick={obtenerTodosLosDocentes}>
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" />
                <p>Cargar Docentes</p>
              </Button>

              <Label
                htmlFor="cargaAcademicaDocente"
                value="Carga académica Docente"
              />
              <Button className="ml-3" onClick={obtenerCargaAcademicaDocente}>
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" />
                <p>Cargar carga académica Docente</p>
              </Button>

              <Label htmlFor="cargaAcademica" value="Carga académica" />
              <Button
                className="ml-3"
                onClick={obtenerCargaAcademicaEstudiante}
              >
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" />
                <p>Cargar carga académica Estudiante</p>
              </Button>

              <Label htmlFor="sedes" value="Sedes" />
              <Button className="ml-3" onClick={obtenerSede}>
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" />
                <p>Cargar Sedes</p>
              </Button>

              <Label htmlFor="carreras" value="Carreras" />
              <Button className="ml-3" onClick={obtenerCarreras}>
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" />
                <p>Cargar Carreras</p>
              </Button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default CargarDatos;

import axios from "./../../interceptor/AxiosInterceptor"
import { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import Swal from "sweetalert2"
import {HiArrowSmLeft,HiOutlineCheckCircle} from 'react-icons/hi'
import { TextInput,Button,ToggleSwitch } from "flowbite-react"

const EditarAmbito = ()=>{

    const apiURl  = process.env.REACT_APP_BACKEND_API_ENDPOINT
    const {idAmbito} = useParams()
    const [ambito,setAmbito] = useState({})
    const [switch1, setSwitch1] = useState(false);

    useEffect(()=>{
        axios.get(apiURl+'/categorizaciones/obtenerAmbito/'+idAmbito)
        .then(res=>{
            setAmbito(res.data.ambito)
            setSwitch1(res.data.ambito.activo)
        }).catch(err=>{
            Swal.fire({text:'Error al obtener ámbito', icon:'error'})
        })
    },[])


    function submitForm(event){
        event.preventDefault()
    }

    function capturarFormulario(event){
        setAmbito({
            ...ambito,
            [event.target.name]:event.target.value
        })
    }

    function enviarForm(){
        ambito['activo'] = switch1;
        axios.post(apiURl+"/categorizaciones/crearAmbito",ambito)
        .then(res=>{
            Swal.fire({text:'Ambito de categorización de docente se ha correctamente',icon:'success'})
        }).catch(err=>{
            Swal.fire({text:err.response.data,icon:'error'})
        })
    }

    return <>
            <h4 className="text-2xl mt-5 text-center">Editar ámbito de categorización</h4>

                <div className="mt-10 flex flex-col items-center justify-center"> 
                    <form onSubmit={submitForm}>
                        <div className="flex items-center justify-between mt-5">
                            <label htmlFor="nombre" className="block text-sm font-medium leading-6 text-gray-900">Nombre</label>
                        </div>
                        <TextInput onChange={capturarFormulario} id="rut" className="w-96" name="nombre" aria-label="nombre" 
                                type="text"  value={ambito.nombre} />
                        <div className="flex items-center justify-between mt-5">
                            <ToggleSwitch checked={switch1} label="Activo" onChange={setSwitch1} />
                        </div>
                        <div className="mt-10 float-left mr-3">
                            <Button  onClick={enviarForm} type="submit"><HiOutlineCheckCircle  className="mr-2 h-5 w-5"/>Guardar</Button>
                        </div>
                        <div className="mt-10">
                             <Link to={'/admin'}>
                                        <Button  type="submit"><HiArrowSmLeft className="mr-2 h-5 w-5" />Volver</Button>
                             </Link>
                        </div>
                    </form>
                </div>
    </>
}

export default EditarAmbito
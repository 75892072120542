import { Card } from "flowbite-react"
import AsistenciaDetail from "./AsistenciaDetail"

const Asistencia = ({estudiante, matriculas})=> {
    return <>
        {matriculas.length > 0 ? matriculas.map(m=><>
            <Card className="mt-3" key={m.id}>
                 <h3 class="text-1xl font-bold dark:text-white pt-3">{m.descCarrera}</h3>
                <AsistenciaDetail matricula={m} />
            </Card>
        </>):<span className="italic text-sm 666">No existen matrículas asociadas al estudiante</span>}
    </>
}

export default Asistencia
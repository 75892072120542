import {
  TextInput,
  ListGroup,
  Textarea,
  Button,
  Avatar,
  Checkbox,
  Label,
} from "flowbite-react";
import { useState, useContext } from "react";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import { Card } from "flowbite-react";
import { AuthContext } from "./../../context/AuthContext";
import { Link } from "react-router-dom";

const DerivarAlerta = () => {
  const [estudiantes, setEstudiantes] = useState([]);
  const [estudianteSeleccionado, setEstudianteSeleccionado] = useState({});
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const [detalleAlerta, setDetalleAlerta] = useState();
  const [rutEstudiante, setRutEstudiante] = useState();
  const { usuarioLogeado } = useContext(AuthContext);
  const [mostrarDeclaracion, setMostrarDeclaracion] = useState(false);

  function submitForm(event) {
    event.preventDefault();
  }

  function seleccionarEstudiante(estudiante) {
    setEstudianteSeleccionado(estudiante);
    setRutEstudiante(estudiante.rut)
    setEstudiantes([]);
  }

  function capturarDetalleAlerta(event) {
    setDetalleAlerta(event.target.value);
  }

  function capturarRutYbuscar(event) {
    setRutEstudiante(event.target.value);
    axios
      .post(apiURl + "/estudiantes/buscarPorRutLive", {
        rut: event.target.value,
      })
      .then((res) => {
        setEstudiantes(res.data.estudiantes);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al buscar estudiante", icon: "error" });
      });
  }

  function deshacerEstudiante() {
    setEstudianteSeleccionado({});
  }

  function derivarAlerta() {
    if (!estudianteSeleccionado.id) {
      Swal.fire({ text: "Debe seleccionar un estudiante", icon: "error" });
      return;
    }

    if (!detalleAlerta) {
      Swal.fire({
        text: "Debe ingresar el detalle de la alerta",
        icon: "error",
      });
      return;
    }

    axios
      .post(apiURl + "/alertas/crearAlertaDae", {
        estudiante: estudianteSeleccionado,
        detalle: detalleAlerta,
        emailUsuarioCreador: usuarioLogeado.email,
      })
      .then((res) => {
        Swal.fire({ text: "Alarta derivada correctamente", icon: "success" });
        setDetalleAlerta({});
        setEstudianteSeleccionado({});
        setDetalleAlerta("");
        setRutEstudiante("");
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }

  return (
    <>
      <h4 className="text-2xl mt-5 text-center">Ingresar derivación DAE</h4>

      <div className="container mt-10 flex flex-col items-center justify-center">
        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="rut"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Rut estudiante
            </label>
          </div>
          <TextInput
            onChange={capturarRutYbuscar}
            id="rut"
            name="rut"
            aria-label="Rut"
            type="text"
            placeholder="Rut"
            value={rutEstudiante}
          />
          <ListGroup>
            {estudiantes.map((est) => (
              <ListGroup.Item
                onClick={() => {
                  seleccionarEstudiante(est);
                }}
                key={est.id}
              >
                {est.nombre} {est.primerApellido} {est.segundoApellido}
              </ListGroup.Item>
            ))}
          </ListGroup>
          {estudianteSeleccionado.id ? (
            <div>
              <Card className="mt-3">
                <Avatar rounded />
                <p className="font-bold">
                  {" "}
                  {estudianteSeleccionado.nombre +
                    " " +
                    estudianteSeleccionado.primerApellido +
                    " " +
                    estudianteSeleccionado.segundoApellido}
                </p>
                <Button onClick={deshacerEstudiante} outline size="xs">
                  Deshacer selección
                </Button>
              </Card>
            </div>
          ) : (
            ""
          )}

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="detalle"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Comente el motivo de derivación
            </label>
          </div>
          <div>
            <Textarea
              value={detalleAlerta}
              onChange={capturarDetalleAlerta}
              id="comment"
              rows={8}
            />
          </div>
          <div>
            <Card className="italic text-gray-700 text-sm mt-3">
              <h5 className="font-bold">Declaración de principios</h5>
              Es deber de los funcionarios del Centro de Formación Técnica
              Estatal de la Región Metropolitana de Santiago,
              <br /> a través de sus unidades, informar toda vez se pesquisen
              dificultades que afecten a él/la estudiante en su proceso <br />
              enseñanza aprendizaje y/o su quehacer diario en la institución y
              realizar la derivación correspondiente <br /> al Departamento de
              Asuntos Estudiantiles (DAE) mediante la ficha de derivación para
              su posterior resolución y
              <br />
              acompañamiento. Como centro de Formación Técnica Estatal de la
              Región Metropolitana de Santiago facilitamos <br />
              el acceso de los/las estudiantes, asegurando la participación,
              permanencia y progreso de todos y todas desarrollando <br />
              competencias con respecto a las diferencias individuales.
              <br />
              Posibilitaremos la implementación de una práctica dentro del aula
              de manera diversificada correspondiente a las necesidades <br />
              educativas del o la estudiante.
              <br />
              La vía de derivación es mediante esta ficha.
              <br />
              1.- Se detecta la problemática
              <br />
              2.-Completa la ficha y la envía por correo electrónico al correo
              asuntosestudiantiles@santiagocft.cl
              <br />
              3.-Donde la encargada evaluará el caso y derivará según
              corresponda, luego de tener una entrevista con el/la estudiante.
              <br />
            </Card>
            <Checkbox id="accept" defaultChecked />
            <Label htmlFor="accept" className="flex">
              Estoy de acuerdo con la &nbsp;
              <Link
                className="text-cyan-600 hover:underline dark:text-cyan-500"
                onClick={() => setMostrarDeclaracion(true)}
              >
                Declaración de principios
              </Link>
            </Label>
          </div>
          <div className="mt-10">
            <Button onClick={derivarAlerta} type="submit">
              Derivar alerta
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DerivarAlerta;

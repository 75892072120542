import { Button, Table } from "flowbite-react"
import { Link } from "react-router-dom"
import { HiTrash, HiPencilAlt } from "react-icons/hi"
import axios from './../../interceptor/AxiosInterceptor'
import Swal from "sweetalert2"

const ItemRowConfiguracion = ({ configuracion }) => {

  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT

  function eliminarConfiguracion() {
    Swal.fire({
      title: 'Está seguro de eliminar la configuración seleccionado ?',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(apiURl + '/configuracion/eliminarConfiguracion', configuracion)
          .then(res => {
            const responseCode = res.status;
            console.log("responseCode", responseCode)
            Swal.fire({ text: 'Se ha eliminado la configuracion correctamente ', icon: 'success' })
          }).catch(err => {
            Swal.fire({ text: 'La configuraicon no fue eliminado correctamente', icon: 'error' });
          })

      }
    })
  }


  return <>
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
        {configuracion.codigo}
      </Table.Cell>
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
        {configuracion.valor}
      </Table.Cell>
     

      <Table.Cell>
        <div className="columns-2">
          <div className="w-0">
            <Link
              className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
              to={'/editarConfiguracion/' + configuracion.id}>
              <Button><HiPencilAlt className="" /></Button>
            </Link>
          </div>
          <div className="w-0">
            <Button color="failure" onClick={eliminarConfiguracion} ><HiTrash className="" /></Button>
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  </>
}
export default ItemRowConfiguracion;

import { Button, Navbar } from "flowbite-react";
import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import MenuPerfilado from "./MenuPerfilado";
import { usePermission } from "react-permission-role";
import { HiOutlineUserCircle } from "react-icons/hi";

const Menu = () => {
  const { setUsuarioLogeado } = useContext(AuthContext);
  const { setUser } = usePermission();
  function logout() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("roles");
    setUser(null);
    setUsuarioLogeado(null);
  }

  return (
    <>
      <AuthContext.Consumer>
        {({ usuarioLogeado, setUsuarioLogeado }) => (
          <>
            <Navbar fluid rounded>
              <Navbar.Brand>
                <img
                  alt="Logo CFTRM"
                  className="mr-3 h-6 sm:h-9"
                  src="https://www.cftestatalrm.cl/wp-content/uploads/2022/12/Recurso-3.png"
                />
              </Navbar.Brand>
              {usuarioLogeado ? (
                <>
                  <div className="flex md:order-2">
                    <Link to="/login">
                      <Button size="xs" onClick={logout}>
                        Salir
                      </Button>
                    </Link>
                    <Navbar.Toggle />
                  </div>
                  <Navbar.Collapse>
                    <MenuPerfilado />
                  </Navbar.Collapse>
                </>
              ) : (
                <>
                  <Link to="/login">
                    <Button>Acceder</Button>
                  </Link>
                  <Navbar.Toggle />
                </>
              )}
            </Navbar>
            <hr />
            <Outlet />
          </>
        )}
      </AuthContext.Consumer>
    </>
  );
};

export default Menu;

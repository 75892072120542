import axios from "./../../interceptor/AxiosInterceptor"
import { Table } from "flowbite-react"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import ItemRowRole from "./ItemRowRole"

const TablaRoles = () => {
    const [roles, setRoles] = useState([])
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    useEffect(() => {

        axios.get(apiURl + '/rolesApi/obtenerTodosLosRoles')
            .then(res => {
                setRoles(res.data.roles)
            })
            .catch(err => {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al obtener los roles',
                })
            })
    }, [])


    return <>
        <Table hoverable className="mt-10">
            <Table.Head>
                <Table.HeadCell>
                    Roles
                </Table.HeadCell>
                <Table.HeadCell>
                    Acciones
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {roles.map(r => <ItemRowRole key={r.id} role={r} />)}
            </Table.Body>
        </Table>

    </>
    
}
export default TablaRoles;
import { Alert,Button } from 'flowbite-react';
import { HiOutlineCheck } from 'react-icons/hi';
import { Badge } from 'flowbite-react';
import { HiClock } from 'react-icons/hi';

export default function DetalleAlerta() {
  return (
      <div class="md:container md:mx-auto ml-5 mt-5">
        <Alert color="warning" withBorderAccent>
            <span>
            <Badge className='w-1/12 mb-5' icon={HiClock}><p>01/10/2023</p></Badge>
                <p>
                <span className="font-medium">
                    Estado: Pendiente
                </span>
                <br></br>
                <span className="font-medium">
                    Info alert!
                </span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tortor enim, ultrices et imperdiet ut, facilisis et risus. Nam luctus posuere enim, ut scelerisque nibh iaculis eget. Mauris in dolor tellus. Nunc sodales volutpat nisi in malesuada. Praesent mattis dapibus arcu et mattis. Nulla ut sem sit amet nisi tempus placerat. Vestibulum iaculis vulputate tortor, ut vulputate tellus fringilla eu. 
                </p>
            </span>
           
            <Button className='mt-5'>
                    <HiOutlineCheck className="mr-2 h-5 w-5" />
                    <p>
                     Marcar como realizado
                    </p>
            </Button>
        </Alert>
        
        <Alert className='mt-5' color="success" withBorderAccent>
            <span>
            <Badge className='w-1/12 mb-5' icon={HiClock}><p>12/09/2023</p></Badge>
                <p>
                <span className="font-medium">
                    Estado: Realizado
                </span>
                <br></br>
                <span className="font-medium">
                    Info alert!
                </span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tortor enim, ultrices et imperdiet ut, facilisis et risus. Nam luctus posuere enim, ut scelerisque nibh iaculis eget. Mauris in dolor tellus. Nunc sodales volutpat nisi in malesuada. Praesent mattis dapibus arcu et mattis. Nulla ut sem sit amet nisi tempus placerat. Vestibulum iaculis vulputate tortor, ut vulputate tellus fringilla eu. 
                </p>
            </span>
        </Alert>
       </div>
  )
}
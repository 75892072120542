import { Table } from "flowbite-react";
import { useEffect } from "react";

const NotasDetail = ({ramo}) => {

    useEffect(()=>{
        console.log(ramo.detalleNota)
    },[])

  return (

    <>
      <Table>
        <Table.Head>
          <Table.HeadCell>Nombre</Table.HeadCell>
          <Table.HeadCell>Nota</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {ramo?.detalleNota?.length > 0 ? (
            ramo.detalleNota.map((n) => (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell>{n.nota}</Table.Cell>
                <Table.Cell>{n.valor}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>No existen datos asociados</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default NotasDetail;

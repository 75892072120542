import { Table } from "flowbite-react"
import { useEffect } from "react"
import { Link } from "react-router-dom"

const RowItemEstudiante = ({estudiante})=>{
  

    return <>
        {estudiante ? 
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
           {estudiante.nombre} {estudiante.primerApellido} {estudiante.segundoApellido}
          </Table.Cell>
          <Table.Cell>
          {estudiante.rut} 
          </Table.Cell>
          <Table.Cell>
          {estudiante.carrera}
          </Table.Cell>
          <Table.Cell>
          {estudiante.viaIngreso.nombre}
          </Table.Cell>
          <Table.Cell>
            <Link
              className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
              to={'/fichaEstudiante/'+estudiante.id}>
              <p>
              Ver ficha
              </p>
            </Link>
          </Table.Cell>
        </Table.Row>
        : ''}
    </>
}

export default RowItemEstudiante
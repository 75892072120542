import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import { Card } from "flowbite-react";

const Asignaturas = () => {
  const { usuarioLogeado } = useContext(AuthContext);
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const [ramos, setRamos] = useState([]);

  useEffect(() => {
    axios
      .get(apiURl + "/docentes/obtenerRamoDocente/" + usuarioLogeado.email)
      .then((res) => {
        setRamos(res.data.ramosDocente);
      })
      .catch((err) => {
        Swal.fire({ text:err.response.data, icon: "error" });
      });
  }, []);

  return (
    <>
      <h4 className="text-2xl mt-5 text-center">Mis Asignaturas</h4>
      {ramos.length > 0 ? (
        ramos.map((r) => (
          <>
            <Card className="mt-3" key={r.id}>
              <h4 class="font-bold dark:text-white pt-3">{r.descRamo}</h4>
              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Carrera:</label>
                  <span>{r.descCarrera}</span>
                </div>
              </div>

              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Cerrada:</label>
                  <span>{r.cerrada}</span>
                </div>
              </div>

              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Sede:</label>
                  <span>{r.descSede}</span>
                </div>
              </div>

              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Tipo:</label>
                  <span>{r.tipo}</span>
                </div>
              </div>

              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Jornada:</label>
                  <span>{r.jornada}</span>
                </div>
              </div>

              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Fecha de inicio:</label>
                  <span>{r.fecInicial}</span>
                </div>
              </div>

              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Fecha de fin:</label>
                  <span>{r.fecFinal}</span>
                </div>
              </div>
            </Card>
          </>
        ))
      ) : (
        <span className="italic text-sm 666 text-center">
            No existen asignaturas asociadas al docente
        </span>
      )}
    </>
  );
};

export default Asignaturas;

import { Table } from "flowbite-react"
import { Link } from "react-router-dom"

const RowItemDocente = ({docente})=>{
  

    return <>
        {docente ? 
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
           {docente.nombre} {docente.primerApellido} {docente.segundoApellido}
          </Table.Cell>
          <Table.Cell>
          {docente.rut} 
          </Table.Cell>
          <Table.Cell>
          {docente.profesion}
          </Table.Cell>
          <Table.Cell>
            <Link
              className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
              to={'/fichaDocente/'+docente.id}>
              <p>
              Ver ficha de docente
              </p>
            </Link>
          </Table.Cell>
        </Table.Row>
        : ''}
    </>
}

export default RowItemDocente
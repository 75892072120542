import { useContext, useState } from "react";
import axios from 'axios';
import swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Button, Modal } from 'flowbite-react';
import { usePermission } from "react-permission-role";


const Login = () => {

    const {setUser} = usePermission()
    const [loginForm, setLoginForm] = useState({})
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

    const  { setUsuarioLogeado} = useContext(AuthContext)
    const [openModal, setOpenModal] = useState(undefined);
    const props = { openModal, setOpenModal };

    const navigate = useNavigate();

    function enviarForm(e){
        e.preventDefault()
    }

    function capturarDatos(e){
      setLoginForm({
        ...loginForm,
        [e.target.name]:e.target.value
      })
    }

    function login(){
        axios.post(apiURl+'/login',{
          ...loginForm
        }).then(res=>{
          sessionStorage.setItem('token',res.data.token)
          sessionStorage.setItem('roles',res.data.roles)
          sessionStorage.setItem('email',res.data.username)

          setUsuarioLogeado({
            token:res.data.token,
            roles:res.data.roles,
            email:res.data.username
          })

          setUser({
            email:res.data.username,
            roles:res.data.roles,
          })

          navigate('/')
          
        }).catch(err=>{
          console.log(err)
          if(err.response)
              swal.fire({text: err.response.data.message,icon: 'error',})
          else
              swal.fire({text: 'Error interno al intentar conectar con la plataforma',icon: 'error',})
          
        })
    }

    return <>
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={enviarForm} method="POST">
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">Correo electrónico</label>
            </div>
            <div className="mt-2">
              <input id="username" name="username" type="text" required  onChange={capturarDatos}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Contraseña</label>
              <div >
                <Button size="xs" color="warning" onClick={() => props.setOpenModal('default')}>¿Olvidaste tu contraseña?</Button>
              </div>
            </div>
            <div className="mt-2">
              <input id="password" name="password" type="password" required onChange={capturarDatos}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <Button type="submit" onClick={login}>Ingresar a la plataforma</Button>
          </div>
        </form>
      </div>
    </div>
    <Modal show={props.openModal === 'default'} onClose={() => props.setOpenModal(undefined)}>
        <Modal.Header>Recuperar contraseña</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Para recuperar la contraseña debes contactar al correo ...
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
}
  
  export default Login;
  
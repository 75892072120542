import { Link } from "react-router-dom"
import { Button } from "flowbite-react"
import {HiOutlinePlusCircle } from 'react-icons/hi'
import TablaTiposAtencion from "./TablaTiposAtencion"

const TiposAtencionDash = ()=>{

    return <>
      <h3 className="text-xl text-center">Mantenedor de tipos de atención</h3>
            <Link to="/crearTipoAtencion">
                <Button className="ml-3">
                    <HiOutlinePlusCircle className="mr-2 h-5 w-5" /><p>Crear tipo atención</p>
                </Button>
            </Link>
            <TablaTiposAtencion/>
    </>

}
    
export default TiposAtencionDash
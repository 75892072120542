import { Button } from "flowbite-react";
import { HiOutlinePencil, HiOutlineDocumentRemove } from "react-icons/hi";
import { Tabs } from "flowbite-react";
import { HiUserCircle } from "react-icons/hi";
import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";

const FichaProspecto = () => {

  const [prospecto, setProspecto] = useState({});
  const { idProspecto } = useParams();

  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  function eliminarProspecto() {
    Swal.fire({
      title: "Está seguro de eliminar al prospecto seleccionado ?",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(apiURl + "/prospectos/eliminarProspecto", prospecto)
          .then((res) => {
            Swal.fire({
              text: "Se ha eliminado el prospecto correctamente",
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({ text: "Error a eliminar el prospecto", icon: "error" });
          });
      }
    });
  }


  useEffect(() => {
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
    axios
      .get(apiURl + "/prospectos/obtenerProspectoxId/" + idProspecto)
      .then((res) => {
        setProspecto(res.data.prospecto);
        console.log(res.data.prospecto);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          text: "Error al acceder a la ficha del prospecto",
        });
      });
  }, []);

  return (
    <>
      <div class="md:container md:mx-auto ml-5 mt-5">
        <h3 class="text-2xl text-gray-700 font-bold mb-6 ml-3">
          {prospecto.nombres} {prospecto.primerApellido}{" "}
          {prospecto.segundoApellido}
        </h3>

        <Link to={"/editarProspecto/" + prospecto.id}>
          <Button className="mb-5" float-left mr-3>
            <HiOutlinePencil className="mr-2 h-5 w-8" />
            Editar Prospecto
          </Button>
        </Link>

        <Button className="mb-5" mr-6 onClick={eliminarProspecto}>
          <HiOutlineDocumentRemove className="mr-2 h-5 w-5" />
          Eliminar Prospecto
        </Button>
      </div>

      <Tabs.Group aria-label="Tabs with underline" Style="underline">
        <Tabs.Item active icon={HiUserCircle} title="Datos personales">
          <Card className="w-full">
            <div>
              <div className="text-left">
                <label className="text-slate-500 pr-3">Rut:</label>
                <span>{prospecto.rut}</span>
              </div>
            </div>

            <div>
              <div className="text-left">
                <label className="text-slate-500 pr-3">Nombre:</label>
                <span>
                  {" "}
                  {prospecto.nombres} {prospecto.primerApellido}{" "}
                  {prospecto.segundoApellido}
                </span>
              </div>
            </div>

            <div>
              <div className="text-left">
                <label className="text-slate-500 pr-3">Fecha Nacimiento:</label>
                <span> {prospecto.fecNacimiento} </span>
              </div>
            </div>

            <div>
              <div className="text-left">
                <label className="text-slate-500 pr-3">Genero:</label>
                <span> {prospecto.genero?.genero}</span>
              </div>
            </div>

            <div>
              <div className="text-left">
                <label className="text-slate-500 pr-3">
                  Correo electrónico:
                </label>
                <span>
                  {prospecto.emailProspecto}
                </span>
              </div>
            </div>

            <div>
              <div className="text-left">
                <label className="text-slate-500 pr-3">
                  Celular:
                </label>
                <span>
                  {prospecto.celular}
                </span>
              </div>
            </div>
          </Card>
        </Tabs.Item>
      </Tabs.Group>
    </>
  );
};
export default FichaProspecto;

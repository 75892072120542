import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext()

export const AuthProvider = ({children}) =>{

    const [usuarioLogeado,setUsuarioLogeado] = useState(null)

    useEffect(()=>{
        
        let usrToken = sessionStorage.getItem('token')
        let usrRoles = sessionStorage.getItem('roles')
        let usrEmail = sessionStorage.getItem('email')

        if(usrRoles)
             usrRoles = sessionStorage.getItem('roles').split(',')
       

        if(usrToken)
            setUsuarioLogeado({token:usrToken,roles:usrRoles,email:usrEmail})
            
    },[])

    return <AuthContext.Provider  value={{usuarioLogeado,setUsuarioLogeado}}>
            {children}
           </AuthContext.Provider>
}
import { Button, Timeline } from "flowbite-react";
import {
  HiOutlineDownload,
  HiPencilAlt,
  HiOutlineLockClosed,
  HiOutlineLockOpen
} from "react-icons/hi";
import { Tabs } from "flowbite-react";
import {
  HiOutlineTable,
  HiClipboardCheck,
  HiUserCircle,
  HiOutlineClipboardList,
} from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import { Card } from "flowbite-react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useContext} from "react";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import { AllowedAccess } from "react-permission-role";
import { ADMIN, DAE, DOCENTE, ESPECIALISTA } from "../util/Constantes";
import ObservacionesDocente from "./observacionesDocente/ObservacionesDocente";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import ListaArchivos from "./accionApoyo/ListaArchivos";
import DatosPersonales from "./DatosPersonales";
import Asistencia from "./Asistencia";
import Notas from "./Notas";


const FichaEstudiante = () => {

  const { idEstudiante } = useParams();
  const [estudiante, setEstudiante] = useState({});
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const { usuarioLogeado } = useContext(AuthContext);
  const [procesosApoyo,setProcesosApoyo] = useState([])
  const isDocente = usuarioLogeado.roles.find((rol) => rol === DOCENTE);
  const [matriculas,setMatriculas] = useState([]);

  function congelarEstudiante() {
    axios
      .post(apiURl + "/estudiantes/congelarEstudiante/" + idEstudiante)
      .then((res) => {
        setEstudiante(res.data.estudiante);
        Swal.fire({ text: "Acción realizada correctamente", icon: "success" });
      })
      .catch((err) => {
        Swal.fire({ text: "Error al realizar la acción", icon: "error" });
      });
  }

  function descongelarEstudiante() {
    axios
      .post(apiURl + "/estudiantes/descongelarEstudiante/" + idEstudiante)
      .then((res) => {
        setEstudiante(res.data.estudiante);
        Swal.fire({ text: "Acción realizada correctamente", icon: "success" });
      })
      .catch((err) => {
        Swal.fire({ text: "Error al realizar la acción", icon: "error" });
      });
  }

  function descargarFicha() {
    axios
      .get(apiURl + "/estudiantes/fichaPdf/" + idEstudiante, {
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        const url = URL.createObjectURL(res.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = estudiante.rut+"_"+estudiante.nombre+"_"+estudiante.primerApellido+"_"+estudiante.segundoApellido+".pdf";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        Swal.fire({ text: error, icon: error });
      });
  }

  useEffect(() => {
    axios
      .get(apiURl + "/estudiantes/obtenerEstudiantePorId/" + idEstudiante)
      .then((res) => {
        setEstudiante(res.data.estudiante);
        setProcesosApoyo(res.data.procesosApoyo)
        setMatriculas(res.data.matriculas)
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener estudiante", icon: "error" });
      });
  }, []);

  return (
    <>
      <div class="md:container md:mx-auto ml-5 mt-5">
        <h3 class="text-2xl text-gray-700 font-bold mb-6 ml-3 text-center">
          {estudiante.nombre} {estudiante.primerApellido}{" "}
          {estudiante.segundoApellido}
        </h3>
        <div className="">
            <AllowedAccess roles={[ADMIN, DAE, ESPECIALISTA]}>
              <Button onClick={descargarFicha} className="mb-5 mr-5 float-left">
                <HiOutlineDownload className="mr-2 h-5 w-5" />
                Descargar ficha en PDF
              </Button>
            </AllowedAccess>
    
            {/*estudiante.congelado ? (
                <AllowedAccess roles={[ADMIN, DAE]}>
                <Button
                  onClick={descongelarEstudiante}
                  className="mb-5 float-left mr-5">
                  <HiOutlineLockOpen className="mr-2 h-5 w-5" />
                  Descongelar
                </Button>
                </AllowedAccess>          ) : (
                <AllowedAccess roles={[ADMIN, DAE]}>  
                <Button
                  onClick={congelarEstudiante}
                  className="mb-5 float-left mr-5">
                  <HiOutlineLockClosed className="mr-2 h-5 w-5" />
                  Congelar
                </Button>
                </AllowedAccess>
                )*/}
          

            <AllowedAccess roles={[ADMIN, DAE]}>
              <Link to={"/editarEstudiante/" + idEstudiante}>
                <Button className="mb-5 float-left mr-5">
                  <HiPencilAlt className="mr-2 h-5 w-5" />
                  Editar
                </Button>
              </Link>
            </AllowedAccess>

          
            <AllowedAccess roles={[ADMIN, DOCENTE]}>
              <Link to={"/crearObservacion/" + idEstudiante}>
                <Button className="mb-5 float-left mr-5">
                  <HiPencilAlt className="mr-2 h-5 w-5" />
                  Agregar observación
                </Button>
              </Link>
            </AllowedAccess>
        </div>
        <div className="clear-both">
        <Tabs.Group  aria-label="Tabs with underline" style="underline">
          <Tabs.Item active icon={HiUserCircle} title="Información general">
            <DatosPersonales estudiante={estudiante} matriculas={matriculas} />
          </Tabs.Item>
          <Tabs.Item disabled={isDocente} icon={MdDashboard} title="Acciones de apoyo y acompañamiento">
              <Timeline>
                {

                    procesosApoyo.length > 0 ?
                    procesosApoyo.map(p=>{
                      if(p.accionesApoyo?.length > 0){
                        return  p.accionesApoyo.map(a=><Timeline.Item key={a.id}>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time>{moment(a.fechaIngreso).format("DD/MM/yyyy hh:mm")}</Timeline.Time>
                            <Timeline.Title>{a.usuario.username}</Timeline.Title>
                            <Timeline.Body>
                            {a.detalle}
                            <ListaArchivos accionApoyo={a} />
                            </Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>)
                      }else{
                        return <div className='text-center'>
                        <span className="text-sm italic">No se han registrado acciones de ayuda o apoyo a este estudiante</span>
                      </div>
                      }
                      
                    })
                  : <div className='text-center'>
                    <span className="text-sm italic">No se han registrado acciones de ayuda o apoyo a este estudiante</span>
                  </div>
                }
                 
              </Timeline>
          </Tabs.Item>
          <Tabs.Item icon={HiClipboardCheck} title="Asistencia">
            <Asistencia estudiante={estudiante} matriculas={matriculas} /> 
          </Tabs.Item>
          <Tabs.Item icon={HiOutlineTable} title="Notas">
            <Notas matriculas={matriculas} />
          </Tabs.Item>
          <Tabs.Item
            icon={HiOutlineClipboardList}
            title="Registro de observaciones de docentes"
          >
            {estudiante.libroObservaciones &&
            estudiante.libroObservaciones.comentariosDocente.length > 0 ? (
              estudiante.libroObservaciones.comentariosDocente.map((c) => (
                <ObservacionesDocente key={c.id} comentario={c} />
              ))
            ) : (
             <div className='text-center'>
              <span className="text-sm italic">No existen observaciones o comentarios asociados a estudiante</span>
             </div>
            )}
          </Tabs.Item>
        </Tabs.Group>
        </div>
      </div>
    </>
  );
};

export default FichaEstudiante;

import { useEffect, useState } from "react";
import axios from "./../../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import { Button } from "flowbite-react";

const DetalleEstudio = ({ idDocente }) => {

  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  const [archivosEstudio, setArchivosEstudio] = useState([]);

  function adjuntarArchivo(event) {
    console.log(event.target.files[0]);

    let formData = new FormData();
    formData.append("idDocente", idDocente);
    formData.append("archivo", event.target.files[0]);

    axios
      .post(apiURl + "/docentes/agregarArchivoEstudio", formData)
      .then((res) => {
        setArchivosEstudio(res.data.archivos)
        Swal.fire({ text: "Archivo agregado correctamente", icon: "success" });
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }
  
  function descargarCertificado(archivo){
    axios
    .get(apiURl + "/docentes/descargarCertificadoEstudio/" + archivo.id, {
      responseType: "blob",
    })
    .then((res) => {
      console.log(res);
      const url = URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = archivo.nombre;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      Swal.fire({ text: error, icon: error });
    });
}


  useEffect(() => {
    axios
      .get(apiURl + "/docentes/obtenerArchivosEstudio/" + idDocente)
      .then((res) => {
        setArchivosEstudio(res.data.archivos);
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }, []);


  return (
    <>
      <div className="text-center flex justify-center">
        <h6 className="text-blueGray-700 text-xl font-bold">
          Estudios/postítulos/certificaciones
        </h6>
      </div>
      <div className="md:container md:mx-auto ml-5 mt-5">
        <div>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="archivo"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Adjuntar certificado
            </label>
          </div>
          <input
            name="archivo"
            onChange={(e) => adjuntarArchivo(e)}
            className="w-96 block text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            id="archivo"
            type="file"
          ></input>
        </div>
      </div>

      <div>
        {archivosEstudio.length > 0
          ? archivosEstudio.map((a) => (
              <div className="mt-10 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {a.nombre}
                </h5>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                  <Button
                    onClick={()=>descargarCertificado(a)}
                    class="text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                  >
                    Descargar certificado
                  </Button>
                </p>
              </div>
            ))
          :<span className='text-xs italic'>No existen certificados registrados</span>}
      </div>
    </>
  );
};

export default DetalleEstudio;

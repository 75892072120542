import { useEffect, useState } from "react";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import { Table } from "flowbite-react";

const AsistenciaDetail = ({ matricula }) => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const [ramos, setRamos] = useState([]);

  useEffect(() => {
    axios
      .get(
        apiURl +
          "/estudiantes/obtenerCargaAcademica/" +
          matricula.codigoMatricula
      )
      .then((res) => {
        setRamos(res.data.ramos);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener carga académica", icon: "error" });
      });
  }, []);

  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadCell>Ramo</Table.HeadCell>
          <Table.HeadCell>Promedio Asitencia</Table.HeadCell>
          <Table.HeadCell>Promedio notas</Table.HeadCell>
          <Table.HeadCell>Estado</Table.HeadCell>
          <Table.HeadCell>Año</Table.HeadCell>
          <Table.HeadCell>Periodo</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {ramos.length > 0 ? (
            ramos.map((r) => (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell>{r.descRamo}</Table.Cell>
                <Table.Cell>{r.promAsistencia}</Table.Cell>
                <Table.Cell>{r.promParciaeles}</Table.Cell>
                <Table.Cell>{r.estado}</Table.Cell>
                <Table.Cell>{r.anio}</Table.Cell>
                <Table.Cell>{r.periodo}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>No existen datos asociados</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default AsistenciaDetail;

import axios from "./../interceptor/AxiosInterceptor"
import {  Rating} from "flowbite-react"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"

const AmbitosFicha = ({ambito,categorizacion})=>{

    const [indicadores,setIndicadores] = useState({})
    const [procentajeGlobal,setPorcentajeGlobal] = useState(0)
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT


    useEffect(()=>{

        axios.get(apiURl+'/categorizaciones/obtenerIndicadoresPorCategorizacionYAmbito/'+categorizacion.id+"/"+ambito.id)
        .then(res=>{
            setIndicadores(res.data.indicadores)
            setPorcentajeGlobal(calcularPorcentajeAmbito(res.data.indicadores))
        }).catch(err=>{
            Swal.fire({text:'Error al obtener indicadores',icon:'error'})
        })

    },[])

    function calcularPorcentajeAmbito(indicadores){
        let result = 0;
        let suma=0;
        let filter = indicadores.filter((i)=>i.ambito.id == ambito.id)

        if(filter.length > 0){
            let nums  = filter.map(i=>i.porcentaje)
            suma = nums.reduce((a,b)=>a+b)
            result = suma/filter.length
        }


        return Math.trunc(result);
    }

    return <>
             <h6>{ambito.nombre} {procentajeGlobal}%</h6>
             {indicadores && indicadores.length > 0 ?  
                        indicadores.map(i=>{
                            return <div>
                                <dl>
                                    <dt className="text-sm text-left font-medium text-gray-500 dark:text-gray-400">{i.nombre}</dt>
                                    <dd className="flex items-center mb-3 ">
                                        <div className="w-full rounded">
                                            <Rating.Advanced percentFilled={i.porcentaje}  className="mb-2"></Rating.Advanced>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        })
                    : <span className="text-xs italic" >No se han registrado indicadores</span> }
    </>
}

export default AmbitosFicha
import axios from "./../../interceptor/AxiosInterceptor";
import { Button, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { HiBan, HiPencilAlt } from "react-icons/hi";

const TablaTiposAtencion = () => {
  const [tiposAtencion, setTiposAtencion] = useState([]);
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  useEffect(() => {
    axios
      .get(apiURl + "/tipoAtencionApi/obtenerTodosLosTiposAtencion")
      .then((res) => {
        setTiposAtencion(res.data.tiposAtencion);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          text: "Error al buscar tipos de atención",
        });
      });
  }, []);

  function eliminarTipo(tipoSeleccionado) {

    Swal.fire({
      title: 'Está seguro de eliminar el tipo de atención ?',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(apiURl+'/tipoAtencionApi/eliminarTipoAtencion',tipoSeleccionado)
        .then(res=>{
          setTiposAtencion(res.data.tiposAtencion);
          Swal.fire({text:'Se ha eliminado el tipo atención correctamente',icon:'success'})
        }).catch(err=>{
          Swal.fire({text:'Error a eliminar',icon:'error'})
        })

      }
    })


    
  }

  return (
    <>
      <Table hoverable className="mt-10">
        <Table.Head>
          <Table.HeadCell>Nombre</Table.HeadCell>
          <Table.HeadCell>Acciones</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {tiposAtencion.map((t) => (
            <Table.Row  key={t.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {t.nombre}
              </Table.Cell>

              <Table.Cell>
                <div className="columns-2">
                  <div className="w-0">
                    <Link
                      className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
                      to={"/editarTipoAtencion/" + t.id}
                    >
                      <Button>
                        <HiPencilAlt className="" />
                      </Button>
                    </Link>
                  </div>
                  <div className="w-0">
                    <Button color="failure" onClick={()=>eliminarTipo(t)} aria-label="Deshabilitar" >
                      <HiBan className="" />
                    </Button>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default TablaTiposAtencion;

import { Link } from "react-router-dom"
import { Button } from "flowbite-react"
import {HiOutlinePlusCircle } from 'react-icons/hi'
import TablaRoles from "./TablaRoles"

const RolesDash = () => {

    return <>
        <h3 className="text-xl text-center">Mantenedor de roles</h3>
        <Link to="/crearRole">
            <Button className="ml-3">
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" /><p>Crear roles</p>
            </Button>
        </Link>
        <TablaRoles />
    </>
}

export default RolesDash;
import axios from "./../../interceptor/AxiosInterceptor"
import { TextInput,Button, Card } from "flowbite-react"
import { useEffect, useState } from "react"
import { HiOutlineCheckCircle, HiXCircle} from 'react-icons/hi'
import Swal from "sweetalert2"
import ItemIndicador from "./ItemIndicador"


const CardAmbito = ({ambito,idCategoria})=>{

    const [mostrarForm,setMostrarForm]  = useState(false)
  
    const [indicador,setIndicador] = useState()
    const [indicadores,setIndicadores] = useState({})
    const [procentajeGlobal,setPorcentajeGlobal] = useState(0)
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT

    function enviarForm(){

            indicador['ambitoId'] = ambito.id
            indicador['idCategoria'] = idCategoria

           
            axios.post(apiURl+'/categorizaciones/agregarIndicador',indicador)
            .then(res=>{
                setIndicadores(res.data.indicadores)
                setMostrarForm(false)
                setIndicador({})
                setPorcentajeGlobal(calcularPorcentajeAmbito(res.data.indicadores))
                Swal.fire({text:'Indicador agregado correctamente', icon:'success'})
            }).catch(err=>{
                Swal.fire({text:err.response.data, icon:'error'})
            })
    }


   
    
    function capturarFormulario(event){
      
            setIndicador({
                ...indicador,
                [event.target.name]:event.target.value
            })
        
    }

    function calcularPorcentajeAmbito(indicadores){
        let result = 0;
        let suma=0;
        let filter = indicadores.filter((i)=>i.ambito.id == ambito.id)

        if(filter.length > 0){
            let nums  = filter.map(i=>i.porcentaje)
            suma = nums.reduce((a,b)=>a+b)
            result = suma/filter.length
        }


        return Math.trunc(result);
    }

    function eliminarIndicador(indi){
       
        Swal.fire({
            title: 'Está seguro de eliminar el indicador seleccionado ?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
                axios.post(apiURl+'/categorizaciones/eliminarIndicador',{
                    'idIndi':indi.id,
                    'idCate':idCategoria
                })
                .then(res=>{
                    setIndicadores(res.data.indicadores)
                    setPorcentajeGlobal(calcularPorcentajeAmbito(res.data.indicadores))
                    Swal.fire({text:'Indicador eliminado correctamente', icon:'success'})
                }).catch(err=>{
                    Swal.fire({text:err.response.data, icon:'error'})
                })
            }
          })
    }
   

    useEffect(()=>{

        axios.get(apiURl+'/categorizaciones/obtenerIndicadoresPorCategorizacion/'+idCategoria+"/"+ambito.id)
        .then(res=>{
            setIndicadores(res.data.indicadores)
            setPorcentajeGlobal(calcularPorcentajeAmbito(res.data.indicadores))
        }).catch(err=>{
            Swal.fire({text:'Error al obtener indicadores',icon:'error'})
        })

    },[])

    return <>
    
        <Card className="w-full mt-3">
                <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                 {ambito.nombre} -   {procentajeGlobal + '%'}
                </h5>
                <div className="mt-5">
                            <Button size="xs"  onClick={()=>setMostrarForm(true)} type="submit"><HiOutlineCheckCircle  className="mr-2 h-5 w-5"/>Añadir indicador</Button>
                </div>
                {mostrarForm ? 
                    <div>
                        <div className="flex items-center justify-between mt-5">
                            <label htmlFor="nombre" className="block text-sm font-medium leading-6 text-gray-900">Nombre</label>
                        </div>
                        <TextInput onChange={capturarFormulario} id="rut" className="w-96" name="nombre" aria-label="nombre" 
                                type="text" />

                        <div className="flex items-center justify-between mt-5">
                            <label htmlFor="calificacion" className="block text-sm font-medium leading-6 text-gray-900">Porcentaje</label>
                        </div>
                        <input onChange={capturarFormulario} name="porcentaje" 
                            value={indicador?.porcentaje ? indicador.porcentaje : 0}
                            className="flex items-center justify-between mt-5 w-96" type="range" id="test5" min="0" max="100" />
                       
                        <TextInput disabled="true" onChange={capturarFormulario} name="porcentaje" 
                            className="w-96 " type="text" min="1" max="7" id="porcentaje"
                            value={indicador?.porcentaje ? indicador.porcentaje+'%' : '0%'}  /> 

                        <div className="mt-10 float-left mr-3">
                                        <Button size="xs"  onClick={enviarForm} type="submit"><HiOutlineCheckCircle  className="mr-2 h-5 w-5"/>Guardar</Button>
                        </div>
                        <div className="mt-10">
                                        <Button size="xs" color="gray" onClick={()=>setMostrarForm(false)}  type="submit"><HiXCircle  className="mr-2 h-5 w-5"/>Cancelar</Button>
                        </div>
                    </div>
                            : ''}
                       
                <hr/>
                <div className="w-50">
                    {indicadores && indicadores.length > 0 ?  
                        indicadores.map(i=>{
                        if(i.ambito.id === ambito.id)    
                            return <ItemIndicador ambito={ambito} indicador={i} eliminarIndi={eliminarIndicador} />

                        })

                        
                    : <span className="text-xs italic" >No se han registrado indicadores</span> }
                    
                </div>

        </Card>
    </>
}

export default CardAmbito
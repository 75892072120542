import { TextInput, Button, Datepicker, Dropdown, Radio, Label } from "flowbite-react";
import { useEffect, useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";

const CrearEstudiante = () => {
  const [estudiante, setEstudiante] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [viasIngreso, setViasIngreso] = useState([]);


  const [genero, setGenero] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);

  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  function submitForm(event) {
    console.log(event);
    event.preventDefault();

  }

  function capturarFormulario(event) {
    
    setEstudiante({
      
      ...estudiante,
      [event.target.name]: event.target.value,
      
    });
    console.log(event.target.value);
  }

  function handleDateChange(value) {
    setEstudiante({
      ...estudiante,
      ["fecNacimiento"]:
        value.getDate() +
        "/" +
        (value.getMonth() + 1) +
        "/" +
        value.getFullYear(),
    });
  }

  function seleccionarViaIngreso(viaIngresoSeleccionada) {
    console.log(viaIngresoSeleccionada);
    setEstudiante({
      ...estudiante,
      viaIngreso: viaIngresoSeleccionada,
    });
  }

   function seleccionarEstadoCivil(estadoCivilSeleccionado) {
    
    console.log(estadoCivilSeleccionado);
    setEstudiante({
      ...estudiante,
      estadoCivil: estadoCivilSeleccionado,
    });
  }
  
  function seleccionarGenero(generoSeleccionado) {
    
    setEstudiante({
      ...estudiante,
      genero: generoSeleccionado,
    });
  }
  function enviarForm() {
    console.log(estudiante);
    axios
      .post(apiURl + "/estudiantes/guardarEstudiante", estudiante)
      .then((res) => {
        console.log(" res ->");
        console.log(res.data);
        Swal.fire({ text: "Estudiante creado correctamente", icon: "success" });
        setEstudiante({
          rut: "",
          nombre: "",
          primerApellido: "",
          segundoApellido: "",
          fecNacimiento: "",
          emailPersonal: "",
          emailInstitucional: "",
          celular: "",
          direccion: "",
          comuna: "",
          ciudad: "",
          nem: ""
        });
      })
      .catch((err) => {
        console.log("err.response.data");
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    setEstudiante({
      ...estudiante,
      [event.target.name]: event.target.value
    })
  };

  useEffect(() => {
    axios
      .get(apiURl + "/viaIngresoApi/obtenerTodasViasIngreso")
      .then((res) => {
        setViasIngreso(res.data.viasIngreso);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener vías de ingreso", icon: "error" });
      });
      axios
      .get(apiURl + "/estudiantes/obtenerTodosLosGeneros")
      .then((res) => {
        setGenero(res.data.genero);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener los generos", icon: "error" });
      });
      axios
      .get(apiURl + "/estudiantes/obtenerTodosLosEstadoCivil")
      .then((res) => {
        setEstadoCivil(res.data.estadoCivil);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener los estado civiles", icon: "error" });
      });
  }, []);

  return (
    <>
      <h4 className="text-2xl mt-5 text-center">Crear estudiante</h4>
      <div className="mt-5 flex flex-col items-center justify-center">
        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="rut"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Rut
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="rut"
            className="w-96"
            name="rut"
            aria-label="Rut"
            type="text"
            placeholder="Rut"
            value={estudiante.rut}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="nombre"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre(s)
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="nombre"
            className="w-96"
            name="nombre"
            aria-label="Nombre completo"
            type="text"
            placeholder="Nombre completo"
            value={estudiante.nombre}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="primerApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Primer Apellido
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="primerApellido"
            className="w-96"
            name="primerApellido"
            aria-label="Primer Apellido"
            type="text"
            placeholder="Primer Apellido"
            value={estudiante.primerApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="segundoApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Segundo Apellido
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="segundoApellido"
            className="w-96"
            name="segundoApellido"
            aria-label="Segundo Apellido"
            type="text"
            placeholder="Segundo Apellido"
            value={estudiante.segundoApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="fechaNacimiento"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Fecha Nacimiento
            </label>
          </div>
          <Datepicker
            onSelectedDateChanged={handleDateChange}
            name="fecNacimiento"
            labelClearButton="Limpar"
            labelTodayButton="Hoy"
            language="cl-CL"
            value={estudiante.fecNacimiento}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailPersonal"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="emailPersonal"
            className="w-96"
            name="emailPersonal"
            aria-label="Correo electrónico"
            type="text"
            placeholder="Correo electrónico"
            value={estudiante.emailPersonal}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailInstitucional"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico institucional
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="emailInstitucional"
            className="w-96"
            name="emailInstitucional"
            aria-label="Correo electrónico institucional"
            type="text"
            placeholder="Correo electrónico institucional"
            value={estudiante.emailInstitucional}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="celular"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Celular Personal
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="celular"
            className="w-96"
            name="celular"
            aria-label="Celular"
            type="text"
            placeholder="Celular"
            value={estudiante.celular}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="direccion"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Dirección
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="direccion"
            className="w-96"
            name="direccion"
            aria-label="direccion"
            type="text"
            placeholder="Direccion"
            value={estudiante.direccion}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="direccion"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Comuna
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="comuna"
            className="w-96"
            name="comuna"
            aria-label="comuna"
            type="text"
            placeholder="Comuna"
            value={estudiante.comuna}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="ciudad"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Ciudad
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="ciudad"
            className="w-96"
            name="ciudad"
            aria-label="ciudad"
            type="text"
            placeholder="Ciudad"
            value={estudiante.ciudad}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="nem"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nem
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="nem"
            className="w-96"
            name="nem"
            aria-label="Nem"
            type="text"
            placeholder="Nem"
            value={estudiante.nem}
          />
          
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="genero"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Genero
            </label>
          </div>
          <Dropdown color="gray" label={estudiante.genero?.id ? estudiante.genero.genero : 'Seleccionar genero' }>
            {genero.length > 0
              ? genero.map((g) => (
                <Dropdown.Item
                  key={g.id}
                  onClick={() => seleccionarGenero(g)}
                >
                  {g.genero}
                </Dropdown.Item>
              ))
              : ""}
          </Dropdown>
          
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="Estado Civil"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Estado civil
            </label>
          </div>
          <Dropdown color="gray" label={estudiante.estadoCivil?.id ? estudiante.estadoCivil.estadoCivil : 'Seleccionar estado civil' }>
            {estadoCivil.length > 0
              ? estadoCivil.map((e) => (
                <Dropdown.Item
                  key={e.id}
                  onClick={() => seleccionarEstadoCivil(e)}
                >
                  {e.estadoCivil}
                </Dropdown.Item>
              ))
              : ""}
          </Dropdown>

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="viaIngreso"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Vía de ingreso
            </label>
          </div>
          <Dropdown color="gray" label={estudiante.viaIngreso?.id ? estudiante.viaIngreso.nombre : 'Seleccionar vía de ingreso' }>
            {viasIngreso.length > 0
              ? viasIngreso.map((v) => (
                <Dropdown.Item
                  key={v.id}
                  onClick={() => seleccionarViaIngreso(v)}
                >
                  {v.nombre}
                </Dropdown.Item>
              ))
              : ""}
          </Dropdown>

          <div className="mt-10">
            <Button onClick={enviarForm} type="submit">
              <HiOutlineCheckCircle className="mr-2 h-5 w-5" />
              Guardar estudiante
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CrearEstudiante;

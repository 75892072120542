import { Accordion } from "flowbite-react";
import { useEffect, useState } from "react";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import NotasDetail from "./NotasDetail";

const NotasRamo = ({ matricula }) => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const [ramos, setRamos] = useState([]);

  useEffect(() => {
    axios
      .get(
        apiURl +
          "/estudiantes/obtenerCargaAcademica/" +
          matricula.codigoMatricula
      )
      .then((res) => {
        setRamos(res.data.ramos);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener carga académica", icon: "error" });
      });
  }, []);

  return (
    <>
      <Accordion collapseAll>
        {ramos.length > 0 ? (
          ramos.map((r) => (
            <Accordion.Panel>
              <Accordion.Title>{r.descRamo}</Accordion.Title>
              <Accordion.Content>
                <NotasDetail ramo={r} />
              </Accordion.Content>
            </Accordion.Panel>
          ))
        ) : (
          <span>No existen datos asociados </span>
        )}
      </Accordion>
    </>
  );
};

export default NotasRamo;

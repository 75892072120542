import { Timeline } from "flowbite-react";
import { useEffect, useState } from "react";
import axios from "./../../interceptor/AxiosInterceptor";
import moment from "moment";

const ObservacionesDocente = ({ comentario }) => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const [comen, setComen] = useState({});

  useEffect(() => {
    setComen(comentario);
  }, []);

  return (
    <>
      <Timeline>
        <Timeline.Item>
          <Timeline.Point />
          <Timeline.Content>
            <Timeline.Time>
              {moment(comen.fecIngreso).format("DD/MM/yyyy HH:mm")}
            </Timeline.Time>
            <Timeline.Title>{comen?.usuario?.username}</Timeline.Title>
            <Timeline.Body>{comen.comentario}</Timeline.Body>
          </Timeline.Content>
        </Timeline.Item>
      </Timeline>
    </>
  );
};

export default ObservacionesDocente;

import { TextInput, Button, Datepicker, Dropdown } from "flowbite-react";
import { useEffect, useState } from "react";
import { HiOutlineCheckCircle, HiArrowSmLeft } from "react-icons/hi";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import { useParams, Link } from "react-router-dom";

const EditarEstudiante = () => {
  const { idEstudiante } = useParams();
  const [estudiante, setEstudiante] = useState({});

  const [generos, setGeneros] = useState([]);
  const [estadosCivil, setEstadosCivil] = useState([]);
  const [viasIngreso, setViasIngreso] = useState([]);

  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  function submitForm(event) {
    event.preventDefault();
  }

  function capturarFormulario(event) {
    setEstudiante({
      ...estudiante,
      [event.target.name]: event.target.value,
    });
  }

  function seleccionarViaIngreso(viaIngresoSeleccionada) {
    console.log(viaIngresoSeleccionada);
    setEstudiante({
      ...estudiante,
      viaIngreso: viaIngresoSeleccionada,
    });
  }

  function seleccionarEstadoCivil(estadoCivilSeleccionado) {
    console.log(estadoCivilSeleccionado);
    setEstudiante({
      ...estudiante,
      estadoCivil: estadoCivilSeleccionado,
    });
  }

  function seleccionarGenero(generoSeleccionado) {
    setEstudiante({
      ...estudiante,
      genero: generoSeleccionado,
    });
  }

  function handleDateChange(value) {
    setEstudiante({
      ...estudiante,
      ["fecNacimiento"]:
        value.getDate() +
        "/" +
        (value.getMonth() + 1) +
        "/" +
        value.getFullYear(),
    });
  }

  function enviarForm() {
    axios
      .post(apiURl + "/estudiantes/guardarEstudiante", estudiante)
      .then((res) => {
        Swal.fire({ text: "Estudiante creado correctamente", icon: "success" });
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }

  useEffect(() => {
    axios
      .get(apiURl + "/estudiantes/obtenerEstudiantePorId/" + idEstudiante)
      .then((res) => {
        setEstudiante(res.data.estudiante);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener estudiante", icon: "error" });
      });

    axios
      .get(apiURl + "/viaIngresoApi/obtenerTodasViasIngreso")
      .then((res) => {
        setViasIngreso(res.data.viasIngreso);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener vías de ingreso", icon: "error" });
      });
    axios
      .get(apiURl + "/estudiantes/obtenerTodosLosGeneros")
      .then((res) => {
        setGeneros(res.data.genero);
      })
      .catch((err) => {
        Swal.fire({ text: "Error al obtener los generos", icon: "error" });
      });
    axios
      .get(apiURl + "/estudiantes/obtenerTodosLosEstadoCivil")
      .then((res) => {
        setEstadosCivil(res.data.estadoCivil);
      })
      .catch((err) => {
        Swal.fire({
          text: "Error al obtener los estado civiles",
          icon: "error",
        });
      });
  }, []);

  return (
    <>
      <h4 className="text-2xl mt-5 text-center">Editar estudiante</h4>
      <div className="mt-10 flex flex-col items-center justify-center">
        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="rut"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Rut
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="rut"
            className="w-96"
            name="rut"
            aria-label="Rut"
            type="text"
            placeholder="Rut"
            value={estudiante.rut}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="nombre"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre(s)
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="nombre"
            className="w-96"
            name="nombre"
            aria-label="Nombre completo"
            type="text"
            placeholder="Nombre completo"
            value={estudiante.nombre}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="primerApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Primer Apellido
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="primerApellido"
            className="w-96"
            name="primerApellido"
            aria-label="Primer Apellido"
            type="text"
            placeholder="Primer Apellido"
            value={estudiante.primerApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="segundoApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Segundo Apellido
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="segundoApellido"
            className="w-96"
            name="segundoApellido"
            aria-label="Segundo Apellido"
            type="text"
            placeholder="Segundo Apellido"
            value={estudiante.segundoApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="fechaNacimiento"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Fecha Nacimiento
            </label>
          </div>
          <Datepicker
            onSelectedDateChanged={handleDateChange}
            name="fecNacimiento"
            labelClearButton="Limpar"
            labelTodayButton="Hoy"
            language="cl-CL"
            value={estudiante.fecNacimiento}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="email"
            className="w-96"
            name="email"
            aria-label="Correo electrónico"
            type="text"
            placeholder="Correo electrónico"
            value={estudiante.emailPersonal}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailInstitucional"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico institucional
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="emailInstitucional"
            className="w-96"
            name="emailInstitucional"
            aria-label="Correo electrónico institucional"
            type="text"
            placeholder="Correo electrónico institucional"
            value={estudiante.emailInstitucional}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="celular"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Celular Personal
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="celular"
            className="w-96"
            name="celular"
            aria-label="Celular"
            type="text"
            placeholder="Celular"
            value={estudiante.celular}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="direccion"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Dirección
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="direccion"
            className="w-96"
            name="direccion"
            aria-label="direccion"
            type="text"
            placeholder="Direccion"
            value={estudiante.direccion}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="direccion"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Comuna
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="comuna"
            className="w-96"
            name="comuna"
            aria-label="comuna"
            type="text"
            placeholder="Comuna"
            value={estudiante.comuna}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="ciudad"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Ciudad
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="ciudad"
            className="w-96"
            name="ciudad"
            aria-label="ciudad"
            type="text"
            placeholder="Ciudad"
            value={estudiante.ciudad}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="nem"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nem
            </label>
          </div>
          <TextInput
            onChange={capturarFormulario}
            id="nem"
            className="w-96"
            name="nem"
            aria-label="Nem"
            type="text"
            placeholder="Nem"
            value={estudiante.nem}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="genero"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Genero
            </label>
          </div>
          <Dropdown color="gray" label={estudiante.genero?.id ? estudiante.genero.genero : 'Seleccionar genero' }>
            {generos.length > 0
              ? generos.map((g) => (
                  <Dropdown.Item
                    key={g.id}
                    onClick={() => seleccionarGenero(g)}
                  >
                    {g.genero}
                  </Dropdown.Item>
                ))
              : ""}
          </Dropdown>

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="Estado Civil"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Estado civil
            </label>
          </div>
          <Dropdown color="gray" label={estudiante.estadoCivil?.id ? estudiante.estadoCivil.estadoCivil : 'Seleccionar estado civil' }>
            {estadosCivil.length > 0
              ? estadosCivil.map((e) => (
                  <Dropdown.Item
                    key={e.id}
                    onClick={() => seleccionarEstadoCivil(e)}
                  >
                    {e.estadoCivil}
                  </Dropdown.Item>
                ))
              : ""}
          </Dropdown>

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="viaIngreso"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Vía de ingreso
            </label>
          </div>
          <Dropdown color="gray"  label={estudiante.viaIngreso?.id ? estudiante.viaIngreso.nombre : 'Seleccionar vía de ingreso' }>
            {viasIngreso.length > 0
              ? viasIngreso.map((v) => (
                  <Dropdown.Item
                    key={v.id}
                    onClick={() => seleccionarViaIngreso(v)}
                  >
                    {v.nombre}
                  </Dropdown.Item>
                ))
              : ""}
          </Dropdown>

          <div className="mt-10 mr-3 float-left">
            <Button onClick={enviarForm} type="submit">
              <HiOutlineCheckCircle className="mr-2 h-5 w-5" />
              Guardar estudiante
            </Button>
          </div>
          <div className="mt-10">
            <Link to={"/fichaEstudiante/" + estudiante.id}>
              <Button type="submit">
                <HiArrowSmLeft className="mr-2 h-5 w-5" />
                Volver a la ficha
              </Button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditarEstudiante;

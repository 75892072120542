import { Link } from "react-router-dom"
import { Button } from "flowbite-react"
import {HiOutlinePlusCircle } from 'react-icons/hi'
import TablaConfiguraciones from "./TablaConfiguraciones"


const Configuracion = () => {
    return <>
        <h3 className="text-xl text-center">Mantenedor de configuración</h3>
        <Link to="/crearConfiguracion">
            <Button className="ml-3">
                <HiOutlinePlusCircle className="mr-2 h-5 w-5" /><p>Crear Configuración</p>
            </Button>
        </Link>
       <TablaConfiguraciones />
    </>

}

export default Configuracion;
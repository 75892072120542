import { useEffect, useState } from "react"
import { TextInput,Button } from "flowbite-react"
import Swal from "sweetalert2"
import axios from './../../interceptor/AxiosInterceptor'
import ItemSwitchRol from "./ItemSwitchRol"
import { Link } from "react-router-dom"
import {HiArrowSmLeft, HiOutlineCheckCircle } from "react-icons/hi"


const CrearUsuario  = ()=>{

    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    const [usuario,setUsuario] = useState({roles:[]})
    const [roles,setRoles] = useState([])

    function submitForm(event){
        event.preventDefault()
    }

    function capturarFormulario(event){
        setUsuario({
            ...usuario,
            [event.target.name]:event.target.value    

        })
    }

    function enviarForm(){
        axios.post(apiURl+"/usuarios/guardarUsuario",usuario)
        .then(res=>{
            Swal.fire({text:'Usuario creado',icon:'success'})
            setUsuario({
                username:'',
                email:'',
                password:'',
                roles:[]
            })
        })
        .catch(err=>{
            Swal.fire({text:err.response.data,icon:'error'})
        })
    }

    useEffect(()=>{
        axios.get(apiURl+'/rolesApi/obtenerRoles')
        .then(res=>{
            setRoles(res.data.roles)
        })
        .catch(err=>{
            Swal({text:'Error al buscar roles',icon:'error'})
        })

    },[])

    return <>
            <h4 className="text-2xl mt-5 text-center">Crear Usuario</h4>
            <div className="mt-10 flex flex-col items-center justify-center"> 
            <form onSubmit={submitForm}>
                    <div className="flex items-center justify-between">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">Nombre Completo</label>
                    </div>
                    <TextInput id="username" onChange={capturarFormulario} name="username" aria-label="Nombre completo" 
                        type="text" placeholder="Nombre completo" value={usuario.username}
                        className="w-96"  /> 

                    <div className="flex items-center justify-between mt-5">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Correo electrónico</label>
                    </div>
                    <TextInput onChange={capturarFormulario} id="email" name="email" aria-label="Correo electrónico" 
                        type="text" placeholder="Correo electrónico" value={usuario.email}
                        className="w-96"  />

                    <div className="flex items-center justify-between mt-5">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Contraseña</label>
                    </div>
                    <TextInput onChange={capturarFormulario} id="password" name="password" aria-label="Enter your password" 
                        type="password" placeholder="Password" value={usuario.password}
                        className="w-96" />
                      
                    <div className="flex grid grid-cols-2 gap-2 mt-5" id="toggle">
                           {roles.map(rol=><ItemSwitchRol key={rol.id} 
                                    setUsuario={setUsuario} rol={rol} usuario={usuario} />)}
                    </div>
                   
                    
                     <div className="columns-2 mt-10">
                            <div>
                                <Button onClick={enviarForm} type="submit"><HiOutlineCheckCircle  className="mr-2 h-5 w-5"/>Guardar</Button>
                            </div>
                            <div>
                                <Link to="/admin">
                                        <Button  type="submit"><HiArrowSmLeft className="mr-2 h-5 w-5" />Volver</Button>
                                </Link>
                            </div>
                     </div>

                </form> 
            </div>
    </>

}

export default CrearUsuario
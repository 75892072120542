import { Textarea, Button, Dropdown } from "flowbite-react";
import { useState, useContext, useEffect } from "react";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import { AuthContext } from "./../../context/AuthContext";
import { useParams } from "react-router-dom";

const AreaDerivarEstudiante = () => {

   const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT

   const {idEstudiante}= useParams()
   const [estudiante,setEstudiante] = useState({})
   const [areas,setAreas] = useState([])
   const [areaSeleccionada,setAreaSeleccionada] = useState([]) 
   const [detelleDerivacion,setDetelleDerivacion] = useState([]) 
   
  function submitForm(e){
    e.preventDefault()
  } 
  
  function derivarEstudiante(){
    console.log('A derivar estudiante')
    console.log(areaSeleccionada)
    console.log(detelleDerivacion)
    console.log(estudiante)
    axios.post(apiURl+'/areaApi/derivarEstudianteArea',{
        'areaId':areaSeleccionada.id,
        'detalle':detelleDerivacion,
        'idEstudiante':estudiante.id
    }).then(res=>{
        Swal.fire({text:'Derivación correcta',icon:'success'})
    }).catch(err=>{
        Swal.fire({text:'Error al derivar el estudiante a área',icon:'error'})
    })
  }

  function marcarArea(area){
    setAreaSeleccionada(area)
  }

  function capturarDetalle(e){
    setDetelleDerivacion(e.target.value)
  }

  useEffect(()=>{
    axios.get(apiURl+'/estudiantes/obtenerEstudiantePorId/'+idEstudiante)
        .then(res=>{
            setEstudiante(res.data.estudiante)
        }).catch(err=>{
            Swal.fire({text:'Error al obtener datos de estudiante', icon:'error'})
        })

    axios.get(apiURl+'/areaApi/obtenerTodasLasAreas')
        .then(res=>{
            setAreas(res.data.areas)
        }).catch(err=>{
            Swal.fire({text:'Error al obtener datos de estudiante', icon:'error'})
        })
  },[])

  return (
    <>
     
      <h4 className="text-2xl mt-5 text-center">Derivar Estudiante a área</h4>

      <div className="mt-10 flex flex-col items-center justify-center">
        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label htmlFor="rut" className="block text-sm font-medium leading-6 text-gray-900">
              Estudiante:<span> {estudiante.rut} - {estudiante.nombre} {estudiante.primerApellido} {estudiante.segundoApellido}</span>
            </label>
          </div>
          <div className="flex items-center justify-between mt-5">
            <label htmlFor="rut" className="block text-sm font-medium leading-6 text-gray-900">
              Area a derivar:
            </label>
          </div>
          <div>
            <Dropdown label="Seleccionar área">
                {areas.length > 0 ? areas.map(a=><Dropdown.Item onClick={()=>marcarArea(a)}>{a.nombreArea}</Dropdown.Item>) : ''}
            </Dropdown>

          </div>
          <div className="flex items-center justify-between mt-5">
            <label htmlFor="detalle" className="block text-sm font-medium leading-6 text-gray-900">
              Detalle de derivación
            </label>
          </div>
          <Textarea  onChange={capturarDetalle} name="detelleDerivacion"  value={detelleDerivacion} id="comment" rows={8} />
          <div className="mt-10">
            <Button onClick={derivarEstudiante} type="submit">
              Derivar estudiante
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AreaDerivarEstudiante;

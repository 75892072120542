import { Button } from 'flowbite-react';
import {  HiStar, HiPencilAlt, HiAcademicCap} from 'react-icons/hi';
import { Tabs } from 'flowbite-react';
import { HiUserCircle,HiOutlineBookmarkAlt } from 'react-icons/hi';
import { MdDashboard } from 'react-icons/md';
import { Card } from 'flowbite-react';
import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from './../interceptor/AxiosInterceptor'
import Swal from 'sweetalert2';
import { AllowedAccess } from "react-permission-role";
import { ADMIN, DAE } from "../util/Constantes";
import Capacitaciones from './capacitaciones/Capacitaciones';
import AmbitosFicha from './AmbitosFicha';
import DetalleEstudio from './estudios/DetalleEstudio';

const FichaDocente = ()=>{

    const {idDocente} = useParams()
    const [docente,setDocente] = useState({})
    const [capacitaciones, setCapacitaciones] = useState([])
    const [ambitos,setAmbitos] = useState([])
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT

    
    useEffect(()=>{
      
        axios.get(apiURl+'/docentes/obtenerDocentePorId/'+idDocente)
        .then(res=>{
            setDocente(res.data.docente)
            setAmbitos(res.data.ambitos)
        }).catch(err=>{
            Swal.fire({text:'Error al obtener docente', icon:'error'})
        })
        

      },[])
      
      function handleActiveTab(tab){
        if(tab === 1){
            axios.get(apiURl+'/docentes/obtenerCapacitaciones/'+idDocente)
            .then(res=>{
                setCapacitaciones(res.data.capacitaciones)
            }).catch(err=>{
                Swal.fire({text:'Error al obtener las capacitaciones del docente', icon:'error'})
            })
        }
      }

      function calcularPorcentajeAmbito(ambito){
        let result = 0;
        let suma=0;
        if(ambito.indicadores && ambito.indicadores.length > 0){
            let nums  = ambito.indicadores.map(i=>i.porcentaje)
            suma = nums.reduce((a,b)=>a+b)
            result = suma/ambito.indicadores.length
        }

        return Math.trunc(result);
    }

    return <>
               <div className="md:container md:mx-auto ml-5 mt-5">
                    <h3 className="text-2xl text-gray-700 font-bold mb-6 ml-3">
                    {docente.nombre} {docente.primerApellido}  {docente.segundoApellido}</h3>

                    <Link className='float-left mr-3' to={'/editarDocente/'+idDocente}>
                        <Button className='mb-5'>
                        <HiPencilAlt className="mr-2 h-5 w-5" />Editar
                        </Button>
                    </Link>
                    
                    <AllowedAccess roles={[ADMIN, DAE]}>
                        <Link className='float-left mr-3' to={'/categorizarDocente/'+idDocente}>
                            <Button className='mb-5'>
                            <HiStar className="mr-2 h-5 w-5" />Categorizar
                            </Button>
                        </Link>
                    </AllowedAccess>

                    <AllowedAccess roles={[ADMIN, DAE]}>
                        <Link className='' to={'/addCapacitacion/'+idDocente}>
                            <Button className='mb-5'>
                            <HiOutlineBookmarkAlt className="mr-2 h-5 w-5" />Añadir capacitación
                            </Button>
                        </Link>
                    </AllowedAccess>
             
             
                <Tabs.Group aria-label="Tabs with underline" 
                Style="underline" onActiveTabChange={(tab) => handleActiveTab(tab)}>
                <Tabs.Item active  icon={HiUserCircle} title="Datos personales">
                    <Card className="w-full">
                        <div>
                            <div className='text-left'>
                            <label className='text-slate-500 pr-3'>Rut:</label>
                            <span>{docente.rut}</span>
                            </div>
                        </div>
                        <div>
                            <div className='text-left'>
                            <label className='text-slate-500 pr-3'>Nombre:</label>
                            <span>{docente.nombre} {docente.primerApellido} {docente.segundoApellido}</span>
                            </div>
                        </div>
                        <div>
                            <div className='text-left'>
                            <label className='text-slate-500 pr-3'>Fecha de ingreso al establecimiento:</label>
                            <span>{docente.fecIngreso}</span>
                            </div>
                        </div>
                        <div>
                            <div className='text-left'>
                            <label className='text-slate-500 pr-3'>Correo electrónico:</label>
                            <span>{docente.emailInstitucional}</span>
                            </div>
                        </div>
                        <div>
                            <div className='text-left'>
                            <label className='text-slate-500 pr-3'>Profesión:</label>
                            <span>{docente.profesion}</span>
                            </div>
                        </div>
                    </Card>
                    <Card className="w-full mt-5">
                        <h3 class="text-2xl text-gray-700 font-bold mb-6 ml-3">
                            Categorización
                        </h3>
                        {
                            docente.categorizacion && docente.categorizacion.length > 0 ? 
                                docente.categorizacion.map(cate=><div >
                                    <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{cate.anio}</h5>
                                    {ambitos.length > 0 ? ambitos.map(a=>
                                        <div>
                                           
                                            <AmbitosFicha ambito={a} categorizacion={cate} />
                                        </div>
                                    ) 
                                    : <span className='text-xs italic'>No existen indicadores registrados</span>}
                                    <hr></hr>
                                </div>)
                            : <span className='text-sm italic'>Sin categorización</span>
                        }
                    </Card>
                </Tabs.Item>
                <Tabs.Item icon={MdDashboard} title="Capacitaciones">
                  {capacitaciones && capacitaciones.length > 0 ? capacitaciones.map(c=><Capacitaciones keys={c.id} capa={c} /> ) 
                  : <span className='text-sm italic'>No se han registrado capacitaciones</span>}
                </Tabs.Item>

                <Tabs.Item icon={HiAcademicCap} title="Estudios/postítulos/certificaciones">
                     <DetalleEstudio idDocente={idDocente} />
                </Tabs.Item>
                </Tabs.Group>

               
        </div>
    </>

}

export default FichaDocente
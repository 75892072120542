import { useContext, useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import axios from './../../interceptor/AxiosInterceptor'
import Swal from "sweetalert2"
import { Textarea, Button, Datepicker,ListGroup } from "flowbite-react"
import { AuthContext } from "../../../context/AuthContext"
import { HiArrowSmLeft } from 'react-icons/hi'
import moment from "moment"



const IngresarAccionApoyo = ()=>{
    
    const {idEstudiante, idAlerta} = useParams()
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    const [estudiante,setEstudiante] = useState({})
    const [detalleAccion,setDetalleAccion] = useState()
    const [fechaIngreso,setFechaIngreso] = useState()
    const {usuarioLogeado} = useContext(AuthContext)
    const [mostrarFormArchivos, setMostrarFormArchivos] = useState(false)
    const [idAccion, setIdAccion] = useState(false)
    const [nombresArchivos,setNombresArchivos] = useState([])
    const classActive = "flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse"
    const classDisabled = "flex items-center text-gray-500 dark:text-gray-400 space-x-2.5 rtl:space-x-reverse"


    function capturarData(event){
        setDetalleAccion(event.target.value)
    }


    function handleDateChange(value){
        setFechaIngreso(value)
    }

    function agregarAccion(){

        axios.post(apiURl+'/especialistas/agregarAccionApoyo',
        {   userEmail:usuarioLogeado.email,
            idEstudiante:idEstudiante,
            detalleAccion:detalleAccion,
            fechaIngreso:fechaIngreso ? moment(fechaIngreso).format('x') : '',
            idAlerta:idAlerta
        })
        .then(res=>{
            console.log(res.data)
            Swal.fire({text:'Se ha añadido el comentario a la ficha del estudiante',icon:'success'})
            setDetalleAccion('')
            setFechaIngreso('')
            setMostrarFormArchivos(true)
            setIdAccion(res.data.idAccion)
        }).catch(err=>{
            Swal.fire({text:err.response.data, icon:'error'})
        })
    }

    function adjuntarArchivo(event){
     
        console.log(idAccion)
        console.log(event.target.files[0])

        let formData = new FormData()
        formData.append("idAccion",idAccion)
        formData.append("archivo",event.target.files[0])

        axios.post(apiURl+'/especialistas/agregarArchivoAccionApoyo',formData)
        .then(res=>{
            setNombresArchivos([...nombresArchivos,event.target.files[0].name])
            Swal.fire({text:'Archivo agregado correctamente', icon:'success'})
        }).catch(err=>{
            Swal.fire({text:err.response.data, icon:'error'})
        })
    }

    useEffect(()=>{
        
        axios.get(apiURl+'/estudiantes/obtenerEstudiantePorId/'+idEstudiante)
        .then(res=>{
          setEstudiante(res.data.estudiante)
        }).catch(err=>{
            Swal.fire({text:'Error al obtener estudiante', icon:'error'})
        })
      },[])

    return <>
      <h3 className="text-2xl text-gray-700 font-bold mb-6 ml-3 mt-10">
         Agregar acción apoyo o ayuda a {estudiante.nombre} {estudiante.primerApellido}{" "}
          {estudiante.segundoApellido}
     </h3>

     <div className="md:container md:mx-auto ml-5 mt-5">

            <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
                <li className={mostrarFormArchivos ? classDisabled : classActive}>
                    <span className="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                        1
                    </span>
                    <span>
                        <h3 className="font-medium leading-tight">Detalle actividad</h3>
                        <p className="text-sm">Ingrese los detalles de la acción de apoyo realizada</p>
                    </span>
                </li>
                <li  className={mostrarFormArchivos ? classActive : classDisabled}>
                    <span className="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                        2
                    </span>
                    <span>
                        <h3 className="font-medium leading-tight">Adjuntos</h3>
                        <p className="text-sm">Adjuntar archivos a modo de respaldo o evidencia</p>
                    </span>
                </li>
            </ol>
            { mostrarFormArchivos ? 
                <div>
                     <div className="flex items-center justify-between mt-5">
                        <label htmlFor="archivo" className="block text-sm font-medium leading-6 text-gray-900">Adjuntar archivos (Opcional)</label>
                    </div>
                    <input name="archivo" onChange={(e)=>adjuntarArchivo(e)} 
                        className="w-96 block text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
                        id="archivo" type="file"></input>
                    <ListGroup className="w-96 mt-3">
                        {nombresArchivos.length > 0 ? nombresArchivos.map(ar=> <ListGroup.Item>{ar}</ListGroup.Item>) : ''  }
                    </ListGroup>    
                     <div className="mt-10">
                        <Link to={'/verProcesosApoyo'}>
                            <Button  type="submit">Finalizar</Button>
                        </Link>
                    </div>
                </div>
                :
                <div>
                <div className="flex items-center justify-between mt-5">
                        <label htmlFor="fechaIngreso" className="block text-sm font-medium leading-6 text-gray-900">Fecha de realización</label>
                </div>
                <Datepicker className="w-96" onSelectedDateChanged={handleDateChange}
                        name="fechaIngreso" labelClearButton="Limpar" labelTodayButton="Hoy"
                        value={fechaIngreso ? moment(fechaIngreso).format('DD-MM-yyyy') : ''}
                        language="cl-CL"/>

                <div className="flex items-center justify-between mt-5">
                    <label htmlFor="detalle" className="block text-sm font-medium leading-6 text-gray-900">Detalle de acción realizada</label>
                </div>
                <Textarea value={detalleAccion} onChange={capturarData}  id="comment"  rows={8} />
            
                <div className="mt-10 float-left mr-3">
                    <Button onClick={agregarAccion}  type="submit">Continuar</Button>
                </div>
                <div className="mt-10">
                        <Link to='/verProcesosApoyo'>
                            <Button  type="submit"><HiArrowSmLeft className="mr-2 h-5 w-5" />Volver</Button>
                        </Link>
                </div>
            </div>
            }
            
      
    </div>
    </>
}

export default IngresarAccionApoyo;
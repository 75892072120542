import { Card } from "flowbite-react";
import moment from "moment/moment";

const DatosPersonales = ({ estudiante, matriculas }) => {
  return (
    <>
     <h3 class="text-2xl font-bold dark:text-white pt-3">Datos personales</h3>
      <Card className="w-full mt-3">
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">Rut:</label>
            <span>{estudiante.rut}</span>
          </div>
        </div>
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">Nombre:</label>
            <span>
              {estudiante.nombre} {estudiante.primerApellido}{" "}
              {estudiante.segundoApellido}
            </span>
          </div>
        </div>
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">Dirección:</label>
            <span>
              {estudiante.direccion}, {estudiante.comuna}, {estudiante.ciudad}
            </span>
          </div>
        </div>
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">Fecha nacimiento:</label>
            <span>{estudiante.fecNacimiento}</span>
          </div>
        </div>
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">
              Correo electrónico institucional:
            </label>
            <span>{estudiante.emailPersonal}</span>
          </div>
        </div>
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">
              Correo electrónico personal:
            </label>
            <span>{estudiante.emailInstitucional}</span>
          </div>
        </div>
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">
              Celular:
            </label>
            <span>{estudiante.celular}</span>
          </div>
        </div>
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">
              Estado actual en sistema seguimiento:
            </label>
            <span>{estudiante.congelado ? "Congelado" : "Vigente"}</span>
          </div>
        </div>
        <div>
          <div className="text-left">
            <label className="text-slate-500 pr-3">NEM:</label>
            <span>{estudiante.nem}</span>
          </div>
        </div>
      </Card>

      <h3 class="text-2xl font-bold dark:text-white pt-3">Matrículas</h3>

      {matriculas.length > 0 ? (
        matriculas.map((matricula) => (
          <>
            <Card className="mt-3" key={matricula.id}>
              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Carrera:</label>
                  <span>{matricula.descCarrera}</span>
                </div>
              </div>
              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Año ingreso:</label>
                  <span>{matricula.anioIngreso}</span>
                </div>
              </div>
              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">
                    Periodo de ingreso:
                  </label>
                  <span>{matricula.periodoIngreso}</span>
                </div>
              </div>
              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">Año matrícula:</label>
                  <span>{matricula.anioMatricula}</span>
                </div>
              </div>
              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">
                    Periodo de matrícula:
                  </label>
                  <span>{matricula.periodoMatricula}</span>
                </div>
              </div>
              
              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">
                    Estado académico:
                  </label>
                  <span>{matricula.estadoAcademico}</span>
                </div>
              </div>

              <div>
                <div className="text-left">
                  <label className="text-slate-500 pr-3">
                    Situación académica:
                  </label>
                  <span>{matricula.situacionAcademico}</span>
                </div>
              </div>
            </Card>
          </>
        ))
      ) :  (
        <><span className="text-sm italic">No hay información de matrículas para este estudiante</span></>
      )}
    </>
  );
};

export default DatosPersonales;

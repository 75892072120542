import { useState } from "react"
import { Button } from "flowbite-react"
import {HiXCircle,HiOutlineCloudDownload} from 'react-icons/hi'
import Swal from "sweetalert2"
import axios from "./../../interceptor/AxiosInterceptor" 

const ListaArchivos = ({accionApoyo})=>{

    const [mostrarListaArchivos, setMostrarListaArchivos] = useState(false)
    const [archivos,setArchivos] = useState([])
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT


    function cargarArchivos(){
        axios.get(apiURl+'/especialistas/obtenerArchivosPorAccionApoyo/'+accionApoyo.id)
        .then(res=>{
            setArchivos(res.data.archivos)
        }).catch(err=>{
            Swal.fire({text:err.response.data, icon:'error'})
        })
    }

    function descargarArchivo(archivo){
       
        axios
        .get(apiURl + "/especialistas/descargarArchivoAccionApoyo/" + archivo.id, {
          responseType: "blob",
        })
        .then((res) => {
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = archivo.nombre;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          Swal.fire({ text: error, icon: error });
        });
    
}

    return <>
        <div>
        <Button className={mostrarListaArchivos ? 'hidden' :'block'} onClick={()=>{setMostrarListaArchivos(true);cargarArchivos()}} size="xs" color="gray" type="submit"><HiXCircle  className="mr-2 h-5 w-5"/>Ver adjuntos</Button>
        <Button className={mostrarListaArchivos ? 'block' :'hidden'} onClick={()=>setMostrarListaArchivos(false)} size="xs" color="gray" type="submit"><HiXCircle  className="mr-2 h-5 w-5"/>Ocultar adjuntos</Button>
        </div>
        {mostrarListaArchivos ? 
                          <div className="mt-3">
                              {archivos.length > 0 ? archivos.map(a=>{
                                        return  <Button onClick={()=>descargarArchivo(a)} className="float-left mr-3" size="xs" outline gradientDuoTone="cyanToBlue">
                                                    <HiOutlineCloudDownload  className="h-5 w-5 text-cyan-600 dark:text-cyan-500" /> {a.nombre}
                                                </Button>
                                    }): ''}
                          </div>
                        :''
                        }
    </>

}

export default ListaArchivos
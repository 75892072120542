import { TextInput, Button, Table, Label, Select } from "flowbite-react";
import { useState } from "react";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import RowItemEstudiante from "./RowItemEstudiante";

//https://tailwindcomponents.com/component/account-card

const BuscadorEstudiantes = () => {
  const [datosBusqueda, setDatosBusqueda] = useState({});
  const [estudiantes, setEstudiantes] = useState([]);
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  function submitForm(event) {
    event.preventDefault();
  }

  function handleInput(event) {
    setDatosBusqueda({
      ...datosBusqueda,
      [event.target.name]: event.target.value,
    });
  }

  function buscarTodosEstudiantes() {
    axios
      .get(apiURl + "/estudiantes/buscarTodosEstudiantes")
      .then((res) => {
        if (res.data.estudiantes && res.data.estudiantes.length > 0)
          setEstudiantes(res.data.estudiantes);
        else {
          setEstudiantes([]);
          Swal.fire({
            text: "No se han encontrado resultados en la búsqueda",
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        Swal.fire({ text: "Error en busqueda de estudiantes", icon: "error" });
      });
  }

  function buscarEstudiantes() {
    axios
      .post(apiURl + "/estudiantes/buscarEstudiantes", datosBusqueda)
      .then((res) => {
        if (res.data.estudiantes && res.data.estudiantes.length > 0)
          setEstudiantes(res.data.estudiantes);
        else {
          setEstudiantes([]);
          Swal.fire({
            text: "No se han encontrado resultados en la búsqueda",
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        Swal.fire({ text: "Error en busqueda de estudiantes", icon: "error" });
      });
  }

  function buscarTodosIngresosEspeciales(){

    axios
    .get(apiURl + "/estudiantes/buscarTodosIngresosEspeciales")
    .then((res) => {
      if (res.data.estudiantes && res.data.estudiantes.length > 0)
        setEstudiantes(res.data.estudiantes);
      else {
        setEstudiantes([]);
        Swal.fire({
          text: "No se han encontrado resultados en la búsqueda",
          icon: "warning",
        });
      }
    })
    .catch((error) => {
      Swal.fire({ text: "Error en busqueda de estudiantes", icon: "error" });
    });
  }

  return (
    <>
      <section className=" py-1 bg-blueGray-50">
        <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-center">
                <h6 className="text-blueGray-700 text-xl font-bold">
                  Busqueda de estudiantes
                </h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={submitForm}>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="rut" value="Rut" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="rut"
                    name="rut"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className="">
                    <Label htmlFor="nombre" value="Nombre" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="nombre"
                    name="nombre"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="primerApellido" value="Primer Apellido" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="primerApellido"
                    name="primerApellido"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="segundoApellido" value="Segundo Apellido" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="segundoApellido"
                    name="segundoApellido"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className="block">
                    <Label
                      htmlFor="emailInstitucional"
                      value="Correo electrónico institucional"
                    />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="emailInstitucional"
                    name="emailInstitucional"
                    type="text"
                  />
                </div>
                <br className="clear-both"></br>
                <div className="mt-5">
                  <Button
                    className="float-left mr-3 mt-3 sm:w-48"
                    onClick={buscarEstudiantes}
                  >
                    Buscar
                  </Button>
                  <Button
                    className="float-left mr-3 mt-3 sm:w-48"
                    onClick={buscarTodosEstudiantes}
                  >
                    Buscar todos
                  </Button>
                  <Button
                    className="float-left mr-3 mt-3"
                    onClick={buscarTodosIngresosEspeciales}
                  >
                    Buscar ingresos especiales
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Table hoverable>
        <Table.Head>
          <Table.HeadCell>Nombre</Table.HeadCell>
          <Table.HeadCell>Rut</Table.HeadCell>
          <Table.HeadCell>Carrera</Table.HeadCell>
          <Table.HeadCell>Vía Ingreso</Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Ver ficha estudiantil</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {estudiantes.length > 0 ? (
            estudiantes.map((e) => (
              <RowItemEstudiante key={e.id} estudiante={e} />
            ))
          ) : (
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-slate-400 italic">
                Realice una busqueda para presentar registros
              </Table.Cell>
            </Table.Row>
          )}
          <RowItemEstudiante />
        </Table.Body>
      </Table>
    </>
  );
};

export default BuscadorEstudiantes;

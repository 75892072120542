import TablaUsuarios from "./TablaUsuarios";
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";

export default function AdminUsuarios(){

    return <>
            <h3 className="text-xl text-center">Mantenedor de Usuarios</h3>
            <Link to="/crearUsuario">
                <Button className="ml-3">
                    <HiOutlinePlusCircle className="mr-2 h-5 w-5" /><p>Crear usuario</p>
                </Button>
            </Link>
            <TablaUsuarios />
        </>

}
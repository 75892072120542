import { Button } from "flowbite-react"
import { Link } from "react-router-dom"
import {HiOutlinePlusCircle } from 'react-icons/hi'
import TablaViasIngreso from "./TablaViasIngreso"

const ViasIngresDash = ()=>{

    return <>
    
    <h3 className="text-xl text-center">Mantenedor de vías de ingreso</h3>
            <Link to="/crearViaIngreso">
                <Button className="ml-3">
                    <HiOutlinePlusCircle className="mr-2 h-5 w-5" /><p>Crear vía de ingreso</p>
                </Button>
            </Link>
            <TablaViasIngreso />
    </>

}

export default ViasIngresDash
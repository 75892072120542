import { Button,  } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';

const ListarCategorizaciones = ()=>{

    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    const [data,setData] = useState([])

    const columns =[
        {
            name: 'Código',
            selector: row => row.id,
            width:'10%'
           
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
           
        },
        {
            name: 'Año',
            selector: row => row.anio,
            
        },
        {
            name: 'Porcentaje',
            selector: row => row.procentaje+'%',
            sortable:true
        },
        {
            name: 'Acciones',
            selector: row => <Link to={'/verCategorizacion/'+row.id+'/'+row.idDocente}><Button size="xs">Ver detalle</Button></Link>,
            sortable:true
        }
    ]


    useEffect(()=>{
        axios.get(apiURl+'/categorizaciones/obtenerTodasCategorizaciones')
        .then(res=>{
            console.log(res.data.datos)
            setData(res.data.datos)
        }).catch(err=>{
           Swal.fire({text:'Error al obtener estudianes en procesos de ayuda o acompañamiento'})
        })
    },[])
    
    return <>
       <div className="text-center flex justify-center mt-10">
                <h6 className="text-blueGray-700 text-xl font-bold">
                    Categorizaciones docente
                </h6>
            </div> 
            <DataTable
                    columns={columns}
                    data={data}          
            />
    </>

}

export default ListarCategorizaciones
import { Tabs } from 'flowbite-react';
import { HiUserCircle, HiLibrary, HiAdjustments,HiViewBoards, HiCollection, HiDownload, HiClipboard, HiDocumentReport } from 'react-icons/hi';
import AmbitosDash from './catigorizacionDocente/AmbitosDash';
import AdminUsuarios from './usuarios/AdminUsuarios';
import TiposAtencionDash from './tiposAtencion/TiposAtencionDash';
import InstitucionesDash from './instituciones/InstitucionesDash';
import ViasIngresDash from './viasIngreso/ViasIngresoDash';
import CargarDatos from './cargarDatos/CargarDatos';
import RolesDash from './roles/RolesDash'
import Configuracion from './configuracion/Configuracion';

const Administracion = () => {
    return <>
          <Tabs.Group aria-label="Default tabs">
            <Tabs.Item active icon={HiUserCircle} title="Usuarios">
                <AdminUsuarios />            
            </Tabs.Item>
            <Tabs.Item  icon={HiAdjustments} title="Categorización docente">
                <AmbitosDash />            
            </Tabs.Item>
            {/* <Tabs.Item  icon={HiUserCircle} title="Areas de derivación">
                <AreaDash />            
            </Tabs.Item> */}
            <Tabs.Item  icon={HiViewBoards} title="Tipos de atención">
                <TiposAtencionDash />          
            </Tabs.Item>
            <Tabs.Item  icon={HiLibrary} title="Instituciones">
                <InstitucionesDash />          
            </Tabs.Item>
            <Tabs.Item  icon={HiCollection} title="Vías de ingreso">
                <ViasIngresDash />          
            </Tabs.Item>
            <Tabs.Item  icon={HiDownload} title="Cargar Datos">
                <CargarDatos />          
            </Tabs.Item>
            <Tabs.Item  icon={HiClipboard} title="Roles">
                <RolesDash />          
            </Tabs.Item>
            <Tabs.Item  icon={HiDocumentReport} title="Configuración">
                <Configuracion />          
            </Tabs.Item>
          </Tabs.Group>
    </>
  };
  
  export default Administracion;
  

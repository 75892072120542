import { useEffect, useState } from "react"
import { TextInput, Button, ToggleSwitch } from "flowbite-react"
import Swal from "sweetalert2"
import axios from './../../interceptor/AxiosInterceptor'
import { Link } from "react-router-dom"
import { HiArrowSmLeft, HiOutlineCheckCircle } from "react-icons/hi"

const CrearConfiguracion = () => {
    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT
    const [configuracion, setConfiguracion] = useState([])
    const [isVigente, setIsVigente] = useState(false);

    function submitForm(event) {
        event.preventDefault()
    }

    function capturarFormulario(event) {
        setConfiguracion({
            ...configuracion,
            [event.target.name]: event.target.value

        })
    }

    function enviarForm() {
        axios.post(apiURl + "/configuracion/guardarConfiguracion", configuracion)
            .then(res => {
                Swal.fire({ text: 'Configuracion creada', icon: 'success' })
                setConfiguracion({
                    codigo: '',
                    valor: '',
                    vigente: ''
                })
            })
            .catch(err => {
                Swal.fire({ text: err.response.data, icon: 'error' })
            })
    }

    function handleToggleChange() {
        const notVigente = !isVigente
        setIsVigente(notVigente);
        setConfiguracion({
            ...configuracion,
            vigente: true
        })
    }

    useEffect(() => {
        axios.get(apiURl + '/configuracion/obtenerTodasLasConfiguraciones')
            .then(res => {
                setConfiguracion(res.data.configuracion)
            })
            .catch(err => {
                Swal({ text: 'Error al buscar configuracion', icon: 'error' })
            })

    }, [])

    return <>
        <h4 className="text-2xl mt-5 text-center">Crear Configuracion</h4>
        <div className="mt-10 flex flex-col items-center justify-center">
            <form onSubmit={submitForm}>
                <div className="flex items-center justify-between">
                    <label htmlFor="codigo" className="block text-sm font-medium leading-6 text-gray-900">Código</label>
                </div>
                <TextInput id="codigo" onChange={capturarFormulario} name="codigo" aria-label="Codigo"
                    type="text" placeholder="Codigo" value={configuracion.codigo}
                    className="w-96" />

                <div className="flex items-center justify-between">
                    <label htmlFor="valor" className="block text-sm font-medium leading-6 text-gray-900">Valor</label>
                </div>
                <TextInput id="valor" onChange={capturarFormulario} name="valor" aria-label="Valor"
                    type="text" placeholder="Valor" value={configuracion.valor}
                    className="w-96" />

                <div className="flex items-center justify-between mt-5">
                    <ToggleSwitch value={configuracion.vigente} checked={isVigente} label="Vigente" onChange={handleToggleChange} />
                </div>

                <div className="columns-2 mt-10">
                    <div>
                        <Button onClick={enviarForm} type="submit"><HiOutlineCheckCircle className="mr-2 h-5 w-5" />Guardar</Button>
                    </div>
                    <div>
                        <Link to="/admin">
                            <Button type="submit"><HiArrowSmLeft className="mr-2 h-5 w-5" />Volver</Button>
                        </Link>
                    </div>
                </div>

            </form >
        </div >
    </>
}

export default CrearConfiguracion;
import DaeDash from "./dashboards/DaeDash";

const HomeDae = () => {
  return (
    <>
      <DaeDash />
    </>
  );
};

export default HomeDae;

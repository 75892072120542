import {
  TextInput,
  Button,
  Datepicker,
  Radio,
  Label,
  Dropdown,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import Swal from "sweetalert2";
import axios from "./../interceptor/AxiosInterceptor";

const CrearProspecto = () => {
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  //el const es una variable que no se puede cambiar
  const [selectedOption, setSelectedOption] = useState("");
  const [generos, setGeneros] = useState([]);
  const [prospecto, setProspecto] = useState({});

  const [rut, setRut] = useState("");
  const [rutError, setRutError] = useState("");

  const validateRut = (inputRut) => {
    // Expresión regular para validar RUT en formato XXXXXXXX-Y o XXXXXXXX-Y/Z
    const rutRegex = /^[0-9]{7,8}-[0-9kK]{1}$/;

    if (!inputRut.match(rutRegex)) {
      setRutError("Formato de RUT incorrecto, sólo usar formato 12345678-9/k");
      return false;
    }

    setRutError("");
    return true;
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    setProspecto({
      ...prospecto,
      [event.target.name]: event.target.value,
    });
  };

  function submitForm(event) {
    event.preventDefault();

    console.log("Selected option:", selectedOption);
  }

  function capturarFormulario(event) {
    setProspecto({
      ...prospecto,
      [event.target.name]: event.target.value,
    });
    if (validateRut(rut)) {
      console.log("RUT válido:", rut);
    } else {
      console.log("RUT inválido");
    }
  }

  function seleccionarGenero(generoSeleccionado) {
    console.log(generoSeleccionado);
    setProspecto({
      ...prospecto,
      genero: generoSeleccionado,
    });
  }

  function handleDateChange(value) {
    setProspecto({
      ...prospecto,
      ["fecNacimiento"]:
        value.getDate() +
        "/" +
        (value.getMonth() + 1) +
        "/" +
        value.getFullYear(),
    });
  }

  function enviarForm() {
    console.log(prospecto);
    prospecto["rut"] = rut;

    axios
      .post(apiURl + "/prospectos/guardarProspecto", prospecto)
      .then((res) => {
        Swal.fire({ text: "Prospecto creado", icon: "success" });
        setProspecto({
          rut: "",
          nombres: "",
          primerApellido: "",
          segundoApellido: "",
          genero: "",
          fecNacimiento: "",
          emailProspecto: "",
          celular:""
        });
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }

  useEffect(() => {
    axios
      .get(apiURl + "/estudiantes/obtenerTodosLosGeneros")
      .then((res) => {
        setGeneros(res.data.genero);
      })
      .catch((err) => {
        Swal.fire({ text: err.response.data, icon: "error" });
      });
  }, []);

  return (
    <>
      <h4 className="text-2xl mt-5 text-center">Crear prospecto</h4>

      <div className="mt-10 flex flex-col items-center justify-center">
        <form onSubmit={submitForm}>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="rut"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Rut
            </label>
          </div>

          <TextInput
            onChange={(e) => setRut(e.target.value)}
            id="rut"
            name="rut"
            type="text"
            value={prospecto.rut}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="nombres"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nombre(s)
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="nombres"
            className="w-96"
            name="nombres"
            aria-label="Nombres"
            type="text"
            placeholder="Nombres"
            value={prospecto.nombres}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="primerApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Primer Apellido
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="primerApellido"
            className="w-96"
            name="primerApellido"
            aria-label="primer Apellido"
            type="text"
            placeholder="Primer Apellido"
            value={prospecto.primerApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="segundoApellido"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Segundo Apellido
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="segundoApellido"
            className="w-96"
            name="segundoApellido"
            aria-label="segundo Apellido"
            type="text"
            placeholder="Segundo Apellido"
            value={prospecto.segundoApellido}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="genero"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Genero
            </label>
          </div>
          <Dropdown color="gray" label="Seleccionar genero">
            {generos.length > 0
              ? generos.map((g) => (
                  <Dropdown.Item
                    key={g.id}
                    onClick={() => seleccionarGenero(g)}
                  >
                    {g.genero}
                  </Dropdown.Item>
                ))
              : ""}
          </Dropdown>
          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="fecNacimiento"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Fecha Nacimiento
            </label>
          </div>

          <Datepicker
            onSelectedDateChanged={handleDateChange}
            id="fecNacimiento"
            name="fecNacimiento"
            value={prospecto.fecNacimiento}
            labelClearButton="Limpiar"
            labelTodayButton="Hoy"
            language="cl-CL"
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailProspecto"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo electrónico
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="emailProspecto"
            className="w-96"
            name="emailProspecto"
            aria-label="email Prospecto"
            type="text"
            placeholder="Email Prospecto"
            value={prospecto.emailProspecto}
          />

          <div className="flex items-center justify-between mt-5">
            <label
              htmlFor="emailProspecto"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Celular
            </label>
          </div>

          <TextInput
            onChange={capturarFormulario}
            id="celular"
            className="w-96"
            name="celular"
            aria-label="celular Prospecto"
            type="text"
            placeholder="celular Prospecto"
            value={prospecto.celular}
          />

          <div className="mt-10">
            <Button onClick={enviarForm} type="submit">
              <HiOutlineCheckCircle className="mr-2 h-5 w-5" />
              Guardar prospecto
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CrearProspecto;

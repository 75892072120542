import { TextInput, Button, Table, Label } from "flowbite-react";
import { useState } from "react";
import axios from "./../interceptor/AxiosInterceptor";
import Swal from "sweetalert2";
import RowItemProspecto from "./RowItemProspecto";

const BuscadorProspectos = () => {
  const [datosBusqueda, setDatosBusqueda] = useState({});
  const [prospectos, setProspectos] = useState([]);

  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;

  function submitForm(event) {
    event.preventDefault();
  }

  function handleInput(event) {
    setDatosBusqueda({
      ...datosBusqueda,
      [event.target.name]: event.target.value,
    });
  }

  function buscarTodosProspectos() {
    axios
      .get(apiURl + "/prospectos/obtenerTodosLosProspectos")
      .then((res) => {
        if (res.data.prospectos && res.data.prospectos.length > 0)
          setProspectos(res.data.prospectos);
        else {
          setProspectos([]);
          Swal.fire({
            text: "No se han encontrado resultados en la búsqueda",
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        Swal.fire({ text: "Error en busqueda de prospectos", icon: "error" });
      });
  }

  function buscarProspectos() {
    axios
      .post(apiURl + "/prospectos/buscarProspectos", datosBusqueda)
      .then((res) => {
        if (res.data.prospectos && res.data.prospectos.length > 0)
          setProspectos(res.data.prospectos);
        else {
          setProspectos([]);
          Swal.fire({
            text: "No se han encontrado resultados en la búsqueda",
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        Swal.fire({ text: "Error en busqueda de prospectos", icon: "error" });
      });
  }

  return (
    <>
      <section className=" py-1 bg-blueGray-50">
        <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-center">
                <h6 className="text-blueGray-700 text-xl font-bold">
                  Busqueda de prospectos
                </h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={submitForm}>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="rut" value="Rut" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="rut"
                    name="rut"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className="">
                    <Label htmlFor="nombres" value="Nombres" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="nombres"
                    name="nombres"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="apellidoPaterno" value="Apellido Paterno" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="apellidoPaterno"
                    name="apellidoPaterno"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className=" block">
                    <Label htmlFor="apellidoMaterno" value="Apellido Materno" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="apellidoMaterno"
                    name="ApellidoMaterno"
                    type="text"
                  />
                </div>
                <div className="float-left mr-3">
                  <div className="block">
                    <Label htmlFor="email" value="Correo electrónico" />
                  </div>
                  <TextInput
                    onChange={handleInput}
                    id="email"
                    name="email"
                    type="text"
                  />
                </div>
                <br className="clear-both"></br>
                <div className="mt-5">
                  <Button
                    className="float-left mr-3 sm:w-48"
                    onClick={buscarProspectos}
                  >
                    Buscar
                  </Button>
                  <Button
                    className="float-left mr-3 sm:w-48"
                    onClick={buscarTodosProspectos}
                  >
                    Buscar todos
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Table hoverable>
        <Table.Head>
          <Table.HeadCell>Nombre</Table.HeadCell>
          <Table.HeadCell>Rut</Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Ver ficha prospectos</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {prospectos.length > 0 ? (
            prospectos.map((e) => <RowItemProspecto key={e.id} prospecto={e} />)
          ) : (
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-slate-400 italic">
                Realice una busqueda para presentar registros
              </Table.Cell>
            </Table.Row>
          )}
          <RowItemProspecto />
        </Table.Body>
      </Table>
    </>
  );
};

export default BuscadorProspectos;


import { useEffect, useState } from "react";
import axios from "./../interceptor/AxiosInterceptor";
import AcordionAlertas from "../alertas/AcordionAlertas";
import { usePermission } from "react-permission-role";

const BandejaAlertas = () => {
  const { isAuthorized } = usePermission();
  const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const [alertas, setAlertas] = useState([]);

  const obtenerAlertas = async () => {
    if (await isAuthorized(["DAE"])) {
      axios
        .get(apiURl + "/alertas/obtenerTodasAlertas")
        .then((res) => {
          if (res.data.alertas) setAlertas(res.data.alertas);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    obtenerAlertas();
  }, []);

  return (
    <>
      <h5 className="mt-3 text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
        Bandeja de alertas derivadas
      </h5>
      {alertas.length > 0 ? (
        <div className="mt-10">
          {alertas.map((a) => (
            <AcordionAlertas alerta={a} metObtienAlertas={obtenerAlertas} />
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BandejaAlertas;

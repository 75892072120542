import { Button } from "flowbite-react"
import moment from "moment"
import Swal from "sweetalert2"
import axios from './../../interceptor/AxiosInterceptor'

const Capacitaciones = ({capa})=>{

    const apiURl = process.env.REACT_APP_BACKEND_API_ENDPOINT

    function descargarCertificadoPdf(){
        axios
        .get(apiURl + "/docentes/descargarCertificadoPdf/" + capa.id, {
          responseType: "blob",
        })
        .then((res) => {
          console.log(res);
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = capa.nombreCurso+".pdf";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          Swal.fire({ text: error, icon: error });
        });
    }

    return <>
            <div>
            <div className="mt-10 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{capa.nombreCurso}</h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"><span className="font-bold">Descripción:</span> {capa.detalle}</p>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"><span className="font-bold">Número de horas: </span>{capa.numHoras}</p>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"><span className="font-bold">Porcentaje de logro: </span>{capa.calificacion+"%"}</p>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"><span className="font-bold">Fecha Inicio:</span> {moment(capa.fecInicio).format('DD/MM/yyyy')}</p>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"><span className="font-bold">Fecha Fin:</span> {moment(capa.fecFin).format('DD/MM/yyyy')}</p>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                    <Button onClick={descargarCertificadoPdf}
                        class="text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Descargar certificado</Button>
                </p>
            </div>
        </div>    
    </>
}

export default Capacitaciones
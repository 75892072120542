import "./App.css";
import FooterApp from "./componentes/footer/Footer";
import Menu from "./componentes/menu/Menu";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./componentes/login/Login";
import Home from "./componentes/home/Home";
import Administracion from "./componentes/administracion/Administracion";
import NoPage from "./componentes/noPage/NoPage";
import { AuthProvider } from "./context/AuthContext";
import BuscadorEstudiantes from "./componentes/buscadorEstudiantes/BuscadorEstudiantes";
import FichaEstudiante from "./componentes/fichaEstudiante/FichaEstudiante";
import DetalleAlerta from "./componentes/alertas/DetalleAlerta";
import EditarUsuario from "./componentes/administracion/usuarios/EditarUsuario";
import CrearUsuario from "./componentes/administracion/usuarios/CrearUsuario";
import CrearEstudiante from "./componentes/crearEstudiante/CrearEstudiante";
import CrearDocente from "./componentes/crearDocente/CrearDocente";
import BuscadorDocentes from "./componentes/buscadorDocentes/BuscadorDocentes";
import FichaDocente from "./componentes/fichaDocente/FichaDocente";
import EditarEstudiante from "./componentes/editarEstudiante/EditarEstudiante";
import EditarDocente from "./componentes/editarDocente/EditarDocente";
import CrearProspecto from "./componentes/crearProspecto/CrearProspecto";
import DerivarAlerta from "./componentes/derivarAlertaDAE/DerivarAlerta";
import { PermissionProvider } from "react-permission-role";
import CrearObservacionDocente from "./componentes/crearObservacion/CrearObservacionDocente";
import EstudiantesAsignados from "./componentes/estudiantesAsignados/EstudiantesAsignados";
import EstudiantesDerivados from "./componentes/estudiantesDerivados/EstudiantesDerivados";
import IngresarAccionApoyo from "./componentes/fichaEstudiante/accionApoyo/IngresarAccionApoyo";
import ListaEstudiantesEnTratamiento from "./componentes/estudiantesAsignadosDAE/ListaEstudiantesEnTratamiento";
import AddCapacitacion from "./componentes/fichaDocente/capacitaciones/AddCapacitacion";
import CrearAmbitos from "./componentes/administracion/catigorizacionDocente/CrearAmbitos.js";
import CategorizarDocente from "./componentes/fichaDocente/categorizar/CategorizarDocente";
import VerCategorizacion from "./componentes/fichaDocente/categorizar/VerCategorizacion";
import EditarAmbito from "./componentes/administracion/catigorizacionDocente/EditarAmbito";
import ListaTodosEstudiantesAsignados from "./componentes/estudiantesAsignadosDAE/ListaTodosEstudiantesAsignados";
import ListarCategorizaciones from "./componentes/categorizacion/ListarCategorizaciones";
import BandejaAlertas from "./componentes/alertas/BandejaAlertas.js";
import BuscadorProspectos from './componentes/buscadorProspectos/BuscadorProspectos';
import FichaProspecto from './componentes/fichaProspecto/FichaProspecto';
import EditarProspecto from './componentes/editarProspecto/EditarProspecto';
import CrearArea from "./componentes/administracion/areas/CrearArea.js";
import EditarArea from "./componentes/administracion/areas/EditarArea.js";
import AreaDerivarEstudiante from "./componentes/areaDerivarEstudiante/AreaDerivarEstudiante.js";
import CrearTipoAtencion from "./componentes/administracion/tiposAtencion/CrearTipoAtencion.js";
import EditarTipoAtencion from "./componentes/administracion/tiposAtencion/EditarTipoAtencion.js";
import CrearInstitucion from "./componentes/administracion/instituciones/CrearInstitucion.js";
import EditarInstitucion from "./componentes/administracion/instituciones/EditarInstitucion.js";
import CrearViaIngreso from "./componentes/administracion/viasIngreso/CrearViaIngreso.js";
import EditarViaIngreso from "./componentes/administracion/viasIngreso/EditarViaIngreso.js";
import CargarDatos from "./componentes/administracion/cargarDatos/CargarDatos.js"
import CrearRole from "./componentes/administracion/roles/CrearRole.js";
import EditarRole from "./componentes/administracion/roles/EditarRole.js";
import CrearConfiguracion from "./componentes/administracion/configuracion/CrearConfiguracion.js";
import EditarConfiguracion from "./componentes/administracion/configuracion/EditarConfiguracion.js";
import Asignaturas from "./componentes/asignaturasDocente/Asignaturas.js";

function App() {
  return (
    <div className="flex flex-col h-screen justify-between">
      <main className="mb-auto h-auto">
      <PermissionProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Menu />}>
                <Route index element={<Home />} />
                <Route path="admin" element={<Administracion />} />
                <Route path="login" element={<Login />} />
                <Route path="buscarEstudiante" element={<BuscadorEstudiantes />}/>
                <Route path="buscarDocente" element={<BuscadorDocentes />} />
                <Route path="fichaEstudiante/:idEstudiante" element={<FichaEstudiante />}/>
                <Route path="fichaDocente/:idDocente" element={<FichaDocente />}/>
                <Route path="misAlertas" element={<DetalleAlerta />} />
                <Route path="editarUsuario/:idUsuario" element={<EditarUsuario />}/>
                <Route path="crearUsuario" element={<CrearUsuario />} />
                <Route path="crearEstudiante" element={<CrearEstudiante />} />
                <Route path="crearDocente" element={<CrearDocente />} />
                <Route path="editarDocente/:idDocente" element={<EditarDocente />}/>
                <Route path="verTodasCategorizaciones" element={<ListarCategorizaciones />}/>
                <Route path="editarEstudiante/:idEstudiante" element={<EditarEstudiante />}/>
                <Route path="crearProspecto" element={<CrearProspecto />} />
                <Route path="buscadorProspectos" element={<BuscadorProspectos />} />
                <Route path="fichaProspecto/:idProspecto" element={<FichaProspecto />} />                  
                <Route path="editarProspecto/:idProspecto" element={<EditarProspecto />} />
                <Route path="derivarAlertaDae" element={<DerivarAlerta />} />
                <Route path="estudiantesAsignados" element={<EstudiantesDerivados />} />
                <Route path="crearObservacion/:idEstudiante"element={<CrearObservacionDocente />} />
                <Route path="ingresarAccionApoyo/:idEstudiante/:idAlerta"element={<IngresarAccionApoyo />} />
                <Route path="verProcesosApoyo" element={<ListaEstudiantesEnTratamiento />} />
                <Route path="verTodosProcesosApoyo" element={<ListaTodosEstudiantesAsignados />} />
                <Route path="addCapacitacion/:idDocente" element={<AddCapacitacion />} />
                <Route path="categorizarDocente/:idDocente" element={<CategorizarDocente />} />
                <Route path="crearAmbitoCategorizacionDocente" element={<CrearAmbitos />} />
                <Route path="verCategorizacion/:cateId/:idDocente"  element={<VerCategorizacion />} />
                <Route path="editarAmbito/:idAmbito" element={<EditarAmbito />} />
                <Route path="bandejaAlertas" element={<BandejaAlertas />} />
                <Route path="areaDerivarEstudiante/:idEstudiante" element={<AreaDerivarEstudiante />} />
                <Route path="crearArea" element={<CrearArea />} />
                <Route path="editarArea/:idArea" element={<EditarArea />} />
                <Route path="crearTipoAtencion" element={<CrearTipoAtencion />} />
                <Route path="editarTipoAtencion/:idTipoAtencion" element={<EditarTipoAtencion />} />
                <Route path="crearInstitucion" element={<CrearInstitucion />} />
                <Route path="editarInstitucion/:idInstitucion" element={<EditarInstitucion/>} />
                <Route path="crearViaIngreso" element={<CrearViaIngreso/>} />
                <Route path="cargarDatos" element={<CargarDatos/>} />
                <Route path="editarViaIngreso/:idViaIngreso" element={<EditarViaIngreso/>} />
                <Route path="crearRole" element={<CrearRole/>} />
                <Route path="editarRole/:idRole" element={<EditarRole/>} />
                <Route path="crearConfiguracion" element={<CrearConfiguracion/>} />
                <Route path="asignaturasDocente" element={<Asignaturas/>} />
                <Route path="editarConfiguracion/:idConfiguracion" element={<EditarConfiguracion/>} />
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </PermissionProvider>
      </main>
      <footer className="h-10">
          <FooterApp></FooterApp>
      </footer>
     
    </div>
  );
}

export default App;

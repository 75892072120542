import { Link } from "react-router-dom"
import { Button, Table } from "flowbite-react"
import {HiOutlinePlusCircle,HiPencilAlt, HiBan } from 'react-icons/hi'
import { useEffect, useState } from "react"
import axios from "./../../interceptor/AxiosInterceptor"
import Swal from "sweetalert2"


const AmbitosDash = ()=>{

    const apiURl  = process.env.REACT_APP_BACKEND_API_ENDPOINT
    const [ambitos,setAmbitos] = useState([])

    useEffect(()=>{
        axios.get(apiURl+'/categorizaciones/obtenerAmbitos')
        .then(res=>{
            setAmbitos(res.data.ambitos)
        }).catch(err=>{
            Swal.fire({text:'Error al obtener ámbitos', icon:'error'})
        })

    },[])

    function eliminarAmbito(ambito){

        Swal.fire({
          title: 'Está seguro de eliminar el ámbito seleccionado ?',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post(apiURl+'/categorizaciones/eliminarAmbito',ambito)
            .then(res=>{
              setAmbitos(res.data.ambitos)
              Swal.fire({text:'Se ha el ámbito correctamente',icon:'success'})
            }).catch(err=>{
              Swal.fire({text:'Error a eliminar el ámbito',icon:'error'})
            })
  
          }
        })
          
         
      }

    return <>
      <h3 className="text-xl text-center">Mantenedor de categorizaciones</h3>
            <Link to="/crearAmbitoCategorizacionDocente">
                <Button className="ml-3">
                    <HiOutlinePlusCircle className="mr-2 h-5 w-5" /><p>Crear ámbito</p>
                </Button>
            </Link>
            <Table hoverable className="mt-10">
            <Table.Head>
                <Table.HeadCell>
                    Nombre
                </Table.HeadCell>
                <Table.HeadCell>
                    Acciones
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
                {ambitos.map(a =><Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {a.nombre}
                    </Table.Cell>
                    <Table.Cell>
                        <div className="columns-2">
                        <div className="w-0">
                            <Link
                            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 "
                            to={'/editarAmbito/'+a.id}>
                            <Button><HiPencilAlt className="" /></Button>
                            </Link>
                        </div>
                        <div className="w-0">
                            <Button onClick={()=>{eliminarAmbito(a)}} color="failure" ><HiBan className="" /></Button>
                        </div>
                        </div>
                    </Table.Cell>
                    </Table.Row>)}
            </Table.Body>
            </Table>
    </>

}

export default AmbitosDash